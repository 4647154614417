import i18next from "i18next";

import ProfileFields from "consts/profileFields";
import currentLanguage from "helpers/currentLanguage";
import team from "assets/images/icons/team.svg";
import student from "assets/images/icons/student.svg";
import location from "assets/images/icons/location.svg";
import height from "assets/images/icons/height.svg";
import religion from "assets/images/icons/religion.svg";
import purpose from "assets/images/icons/purpose.svg";
import army from "assets/images/icons/military.svg";
import lang from "assets/images/icons/lang.svg";
import health from "assets/images/icons/health.svg";
import hobbiesIcon from "assets/images/icons/hobbiesIcon.svg";
import characteristicsIcon from "assets/images/icons/characteristicsIcon.svg";
import Cohen from "consts/cohen";
import isMobile from "helpers/isMobile";
import { useState } from "react";

export default ({ profile }) => {
	const [isMobileDevice] = useState(isMobile());

	const profileValues = (list, type) => {
		let outputString = "";
		list = list.filter((item) => ![5, 6, 7, 8].includes(item));

		if (!list || !list?.length) return "";

		for (let i = 0; i < list.length; i++) {
			outputString += i18next.t(`${type}.${list[i]}`);
			if (i < list.length - 1) {
				outputString += ", ";
			} else {
				outputString += ".";
			}
		}
		return outputString;
	};

	return (
		<div className="listOfDesc">
			{profile.maritalStatus && (
				<div className="rowOfDesc">
					<div className="iconOfDesc">
						<img src={team} alt="team" />
					</div>
					<p className="txtOfDesc">
						{i18next.t(`MaritalStatus_${profile.gender}.${profile.maritalStatus}`)}

						{profile && profile.hasChildren
							? `${
									profile.ChildrenCount
										? " + " + profile.ChildrenCount
										: " " + i18next.t("Edit Profile.Has children")
							  }`
							: ""}
					</p>
				</div>
			)}

			{(profile.educationalInstitution || profile.education || profile.profession) && (
				<div className="rowOfDesc">
					<div className="iconOfDesc">
						<img src={student} alt="student" />
					</div>
					{(profile.educationalInstitution || profile.education || profile.profession) && (
						<p className="txtOfDesc">
							{profile.profession && i18next.t(`Profession.${profile.profession}`)}
							{profile.profession && (profile.educationalInstitution || profile.education) && ", "}
							{profile.educationalInstitution?.map(
								(educationalInstitution) =>
									i18next.t(`Educational Institution.${educationalInstitution}`) +
									(profile.education ? ", " : "")
							)}
							{profile.education
								?.map((education) => i18next.t(`Education.${education}`))
								.join(", ")}
						</p>
					)}
				</div>
			)}
			{profile[ProfileFields.Cites[currentLanguage()]] && (
				<div className="rowOfDesc">
					<div className="iconOfDesc">
						<img src={location} alt="location" />
					</div>
					{profile[ProfileFields.Cites[currentLanguage()]] && (
						<p className="txtOfDesc text-truncate">
							{profile[ProfileFields.Cites[currentLanguage()]]}
						</p>
					)}
				</div>
			)}

			{profile.height && (
				<div className="rowOfDesc">
					<div className="iconOfDesc">
						<img src={height} alt="height" />
					</div>
					{profile.height && (
						<p className="txtOfDesc">
							{profile.height} {i18next.t("CM")}
						</p>
					)}
				</div>
			)}

			<div className="rowOfDesc">
				<div className="iconOfDesc">
					<img src={religion} alt="religion" />
				</div>
				<p className="txtOfDesc">
					{profile.stance && i18next.t(`Stance_${profile.gender}.${profile.stance}`)}
					{profile.religion && ", " + i18next.t(`Religion.${profile.religion}`)}
					{profile.prayerTime && ", " + i18next.t(`PrayerTime_0.${profile.prayerTime}`)}
					{profile.headCover && ", " + i18next.t(`Head Cover.${profile.headCover}`)}
					{profile.clothing &&
						", " + i18next.t("Clothing.title") + ": " + i18next.t(`Clothing.${profile.clothing}`)}
					{profile.cohen === Cohen.Yes && ", " + i18next.t("Cohen.Title")}
					{profile.keepShabbat &&
						", " + i18next.t(`KeepShabbat_${profile.gender || 0}.${profile.keepShabbat}`)}
					{profile.kosher &&
						", " + i18next.t("KosherTitle") + i18next.t(`Kosher.${profile.kosher}`)}
					{profile.kindKipa && ", " + i18next.t(`KindKipa.${profile.kindKipa}`)}
					{profile.toraTime &&
						", " + i18next.t("ToraTime.title") + "-" + i18next.t(`ToraTime.${profile.toraTime}`)}
				</p>
			</div>

			{profile.lookingFor && (
				<div className="rowOfDesc">
					<div className="iconOfDesc">
						<img src={purpose} alt="purpose" />
					</div>
					{profile.lookingFor && (
						<p className="txtOfDesc">
							{i18next.t("LookingForTitle")}
							{profile.lookingFor
								.map((lookingFor) => i18next.t(`LookingFor.${lookingFor}`))
								.join(", ")}
						</p>
					)}
				</div>
			)}
			{profile.army && (
				<div className="rowOfDesc">
					<div className="iconOfDesc">
						<img src={army} alt="army" />
					</div>
					{profile.army && (
						<p className="txtOfDesc army">
							{typeof profile.army == "object"
								? profileValues(profile.army, "Army")
								: i18next.t(`Army.${profile.army}`)}
						</p>
					)}
				</div>
			)}

			{(profile.origin || profile.languages) && (
				<div className="rowOfDesc">
					<div className="iconOfDesc">
						<img src={lang} alt="language" />
					</div>
					{(profile.origin || profile.languages) && (
						<p className="txtOfDesc">
							{profile.languages &&
								i18next.t("ProfileLanguagesTitle") +
									" - " +
									profile.languages.map(
										(language) => " " + i18next.t(`Profile Languages.${language}`)
									)}
							{profile.languages && profile.origin && ", "}
							{profile.origin &&
								i18next.t("OriginTitle") +
									" - " +
									i18next.t(`Origin_${profile.gender}.${profile.origin}`)}
						</p>
					)}
				</div>
			)}
			{(profile.health || profile.smoking || profile.food) && (
				<div className="rowOfDesc">
					<div className="iconOfDesc">
						<img src={health} alt="health" />
					</div>
					{(profile.health || profile.smoking || profile.food) && (
						<p className="txtOfDesc">
							{profile.health &&
								i18next.t("HealthTitle") + " - " + i18next.t(`Health.${profile.health}`)}

							{profile.smoking && profile.health && ", "}

							{profile.smoking && i18next.t(`Smoking_${profile.gender}.${profile.smoking}`)}

							{profile.food && (profile.smoking || profile.health) && ", "}

							{profile.food &&
								i18next.t("Food.title") +
									"-" +
									(typeof profile.food == "object"
										? profileValues(profile.food, "Food")
										: i18next.t(`Food.${profile.food}`))}
						</p>
					)}
				</div>
			)}
			{profile.hobbies && (
				<div className="rowOfDesc">
					<div className="iconOfDesc">
						<img src={hobbiesIcon} alt="hobbiesIcon" />
					</div>
					{profile.hobbies && (
						<p className="txtOfDesc">
							{profile.hobbies?.map((hobby) => i18next.t(`hobbies.${hobby}`)).join(", ")}
						</p>
					)}
				</div>
			)}

			{profile.characteristics && (
				<div className="rowOfDesc">
					<div className="iconOfDesc">
						<img src={characteristicsIcon} alt="characteristicsIcon" />
					</div>
					{profile.characteristics && (
						<p className="txtOfDesc">
							{profile.characteristics
								?.map((character) => i18next.t(`Characteristics.${character}`))
								.join(", ")}
						</p>
					)}
				</div>
			)}
		</div>
	);
};
