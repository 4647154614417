import PropTypes from "prop-types";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUserProfile } from "redux/profile/selectors";
import DateList from "../DateList";

const drawerBleeding = 56;

const Root = styled("div")(({ theme }) => ({
	height: "100%",
}));

const StyledBox = styled("div")(({ theme }) => ({
	backgroundColor: "#2D5086",
	color: "#fff",
}));

const Puller = styled("div")(({ theme }) => ({
	width: 100,
	height: 4,
	backgroundColor: grey[200],
	borderRadius: 3,
	position: "absolute",
	top: 8,
	left: "calc(50% - 50px)",
}));

function DrawerVideoStatus({
	window,
	speedData,
	currentDate,
	setListDates = () => {},
	toggleDrawerParent = () => {},
	closeVideoDate = () => {},
	isDrawerOpen,
	dateDuration,
	setDateDuration,
}) {
	const [open, setOpen] = useState(false);
	const { name } = useSelector(getUserProfile);

	const toggleDrawer = (newOpen) => () => {
		setOpen(newOpen);
	};

	// This is used only for the example
	const container = window !== undefined ? () => window().document.body : undefined;

	useEffect(() => {
		setOpen(isDrawerOpen);
	}, [isDrawerOpen]);

	useEffect(() => {
		toggleDrawerParent(open);
	}, [open]);
	return (
		<Root>
			<CssBaseline />
			<Global
				styles={{
					".MuiDrawer-root > .MuiPaper-root": {
						height: `calc(80% - ${drawerBleeding}px)`,
						overflow: "visible",
					},
				}}
			/>

			<SwipeableDrawer
				className="videoDrawerWrapper"
				container={container}
				anchor="bottom"
				open={open}
				onClose={toggleDrawer(false)}
				onOpen={toggleDrawer(true)}
				swipeAreaWidth={drawerBleeding}
				disableSwipeToOpen={false}
				ModalProps={{
					keepMounted: true,
				}}>
				<StyledBox
					sx={{
						position: "absolute",
						top: -drawerBleeding,
						borderTopLeftRadius: 8,
						borderTopRightRadius: 8,
						visibility: "visible",
						right: 0,
						left: 0,
					}}>
					<Puller />
					<Typography sx={{ p: "17px", textAlign: "center", color: "#fff", fontWeight: "bold" }}>
						{`היי ${name ?? ""}, הלוז שלך לאירוע`}
					</Typography>
				</StyledBox>
				<StyledBox
					sx={{
						height: "100%",
						overflow: "scroll",
					}}
					className="wrapDatesLines">
					<DateList
						eventKey={speedData?.key}
						speedData={speedData}
						currentDate={currentDate}
						selectDate={(date) => {}}
						setListDates={setListDates}
						dateDuration={dateDuration}
						setDateDuration={setDateDuration}
						closeVideoDate={closeVideoDate}
						toggleDrawer={toggleDrawerParent}
					/>

					<Box className="importantToKnow">{`חשוב לדעת - ניתן לערוך ולשנות את התשובות שלך למפגשי הוידאו דייט, עד שעה לאחר סיום האירוע. לאחר מכן, במידה ושניכם סימנתם "כן" לשאלת המשכיות הקשר ביניכם - נעביר ביניכם את מספרי הטלפון, ותוכלו ליצור קשר זה עם זו. בהצלחה :)`}</Box>
				</StyledBox>
			</SwipeableDrawer>
		</Root>
	);
}

DrawerVideoStatus.propTypes = {
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window: PropTypes.func,
};

export default DrawerVideoStatus;
