import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import i18next from "i18next";
import _ from "lodash";

import ArrowUpImage from "assets/images/purchase_account_arrow_up.svg";
import usePopups from "hooks/usePopups";
import useShowJoinNowBtn from "hooks/useShowJoinNowBtn";
import { getIsPlatformIOS, getIsPlatformWeb } from "redux/init/selectors";
import { getUserProfile } from "redux/profile/selectors";
import {
	getSale,
	getSubscriptionData,
	getSubscriptionsIsSoonExpired,
} from "redux/subscription/selectors";
import { Button } from "reactstrap";
import { getAgeByDob } from "helpers/date";
import speedDateStatus from "consts/speedDateStatus";
import speedDatingService from "services/speedDatingService";
import useNavigator from "hooks/useNavigator";
import moment from "moment";
import promotion from "services/promotion";
import { getUserGeneral } from "redux/general/selectors";

export default () => {
	const navigate = useNavigator();
	const isPlatformWeb = useSelector(getIsPlatformWeb);
	const subscription = useSelector(getSubscriptionData);
	const subscriptionsIsSoonExpired = useSelector(getSubscriptionsIsSoonExpired);

	const { gender } = useSelector(getUserProfile);
	const currentUser = useSelector(getUserProfile) || {};
	const showJoinNowBtn = useShowJoinNowBtn();
	const {
		showSubscriptionPopup,
		showSubscriptionDealPopup,
		showSaleSubscriptionPopup,
		showPromotionsSalePopup,
	} = usePopups();
	const sale = useSelector(getSale);
	const animatedName = "animate__bounceInDown";
	const isPlatformIOS = useSelector(getIsPlatformIOS);
	const userGeneral = useSelector(getUserGeneral);

	const getUrl = () => {
		if (process.env.REACT_APP_ENV === "shlish") {
			if (isPlatformIOS) return "https://apple.co/48O1JQ6";
			return "https://bit.ly/42joSrq";
		} else {
			return "https://jsmart.app/invite/Ewq2";
		}
	};
	const goToTheStore = () => window.open(getUrl(), "_blank");

	const navigateToAccountVideoDate = () => {
		navigate.navigateToAccountVideoDate();
	};

	const handleSaleSubscriptionPopup = () => {
		showSaleSubscriptionPopup(undefined, undefined, true, null);
	};

	const [current, setCurrent] = useState();
	const [speedDating, setSpeedDating] = useState();

	useEffect(async () => {
		if (_.isNil(currentUser.uid)) {
			return;
		}
		const data = await speedDatingService.getNextSpeedDate();
		if (!data) return;
		let sData;
		const age = getAgeByDob(currentUser.dob);
		sData = Object.values(data || {}).find((ev) => {
			let filterAge;
			if (currentUser.gender == 1) {
				filterAge = ev?.femaleFilters;
			} else {
				filterAge = ev?.maleFilters;
			}
			return (
				ev?.status === speedDateStatus.start && filterAge.fromAge <= age && filterAge.toAge >= age
			);
		});
		if (sData) {
			const userInspeedDate = await speedDatingService.getUserInSpeedDate(
				sData?.key,
				currentUser?.uid
			);

			if (
				!userInspeedDate &&
				sData?.endUsersRegister &&
				!moment().isAfter(moment(sData?.endUsersRegister))
			)
				setSpeedDating(sData);
		}
	}, [currentUser.uid]);

	useEffect(async () => {
		if (_.isNil(currentUser.uid)) {
			return;
		}

		if (false) {
			//need change false to speedDating
			setCurrent({
				type: "speedDating",
				header: "הירשם לוידאו דייט",
				func: navigateToAccountVideoDate,
				color: "secondary",
			});
		} else if (!_.isNil(showJoinNowBtn)) {
			if (showJoinNowBtn) {
				if (subscriptionsIsSoonExpired) {
					setCurrent({
						type: "joinNow",
						header: `${currentUser.name}, מבצע במיוחד בשבילך!`,
						func: showSubscriptionDealPopup,
						color: "success",
						className: "red-bg",
					});
				} else if (!!sale && !!sale.label) {
					setCurrent({
						type: "sale",
						header: sale.label,
						func: handleSaleSubscriptionPopup,
						color: "success",
						className: "red-bg",
					});
				} else {
					setCurrent({
						type: "joinNow",
						header: i18next.t(`Strip.Join Now_${gender}`),
						func: showSubscriptionPopup,
						color: "success",
						className: "",
					});
				}
			} else {
				const promotionResult = await promotion.getActivePromotionsSalePlan();

				if (!userGeneral?.isPromoteSale && !!promotionResult) {
					const title = `${currentUser.name}, ${currentUser.gender ? "קדמי" : "קדם"} את הכרטיס שלך`;
					setCurrent({
						type: "joinNow",
						header: title,
						func: () => showPromotionsSalePopup(promotionResult),
						color: "success",
						className: "",
					});
				} else setCurrent(null);
			}
		}
	}, [showJoinNowBtn, isPlatformWeb, subscription, currentUser.uid, speedDating]);

	return (
		<>
			{current && (
				<Button
					className={`bottomStrip ${current?.className}`}
					onClick={current.func}
					color={current.color}>
					<img src={ArrowUpImage} />
					<div className={`animate__animated ${animatedName}`} style={{ minWidth: "fit-content" }}>
						{current.header}
					</div>
					<img src={ArrowUpImage} />{" "}
				</Button>
			)}
		</>
	);
};
