import { JaaSMeeting } from "@jitsi/react-sdk";
import { useEffect, useState } from "react";
import CountdownTimer from "./CountdownTimer";
import Feedback from "./Feedback";
import { Box } from "@mui/material";
import speedDatingService from "services/speedDatingService";
import moment from "moment";

export default function VideoChat({ currentDate, speedData, setRemainingTime, dateDuration }) {
	const [selectDate, setSelectDate] = useState(false);

	const calculateTimeRange = (start, duration, currentTime = moment()) => {
		const [startHour, startMinute] = start.split(":");
		const startTime = moment().set({ hour: startHour, minute: startMinute, second: 0 });
		const endTime = moment(startTime).add(duration, "minutes");

		const isWithinTimeRange = currentTime.isBetween(startTime, endTime, null, "[]");

		let remainingTime = 0;

		if (isWithinTimeRange) {
			const totalRemainingSeconds = endTime.diff(currentTime, "seconds");
			remainingTime = Math.max(totalRemainingSeconds, 0);
		}

		return {
			isInRange: isWithinTimeRange,
			remainingTime: remainingTime,
		};
	};

	useEffect(async () => {
		if (currentDate) {
			const time = calculateTimeRange(currentDate.time, dateDuration);
			if (time && time.isInRange) {
				setRemainingTime(time.remainingTime);
			}

			const data = await speedDatingService.getChatVideoRoom(speedData?.key, currentDate?.uidMatch);
			if (data?.data?.success) {
				setSelectDate({ token: data.data.token, roomName: data.data.roomName, ...currentDate });
			}
		}
		return () => {
			setRemainingTime(0);
		};
	}, [currentDate]);

	return (
		<>
			{!!currentDate && !!selectDate?.token && (
				<Box className="jeesMeetingWrapper">
					<JaaSMeeting
						appId={"vpaas-magic-cookie-666af2da7dc64003ac0ba5e77b50b790"}
						roomName={selectDate.roomName}
						configOverwrite={{
							disableDeepLinking: true,
							//toolbarButtons:["chat"]
							breakoutRooms: { hideAddRoomButton: true, hideJoinRoomButton: true },
						}}
						jwt={selectDate.token}
						//spinner={CustomSpinner}
						getIFrameRef={(iframeRef) => {
							iframeRef.style.height = "100%";
							iframeRef.className = "jeesIframe";
						}}
						onApiReady={(externalApi) => console.log("test", externalApi)}
					/>
				</Box>
			)}
		</>
	);
}
