import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";

import Sidebar from "views/Sidebar";
import Content from "views/Content";
import ChatReceived from "components/chatSideBar";
import ChatContent from "components/chatContent";
import Loader from "components/Loader";
import { getUserProfile } from "redux/profile/selectors";
import BottomStrip from "components/BottomStrip";
import isMobile from "helpers/isMobile";
import { useEffect } from "react";
import usePopups from "hooks/usePopups";
import { cancelActiveUser } from "redux/chat/actions";
import { getAllChatUsers } from "redux/chatReceived/selectors";
import chatTabs from "consts/chatTabs";
import { clearUnviewedNewMessages } from "redux/chatMeta/actions";
import userNotificationsService from "services/unreadNotification.service";
import notificationUnreadTypes from "consts/notificationUnreadTypes";
import useHelper from "hooks/useHelper";
import useNavigator from "hooks/useNavigator";
import { PushNotifications } from "@capacitor/push-notifications";
import { getIsPlatformIOS } from "redux/init/selectors";
//import { Badge } from "@capawesome/capacitor-badge";

export default () => {
	const currentUser = useSelector(getUserProfile);
	const isUserLoggedIn = !isEmpty(currentUser);
	const active_userId = useSelector((state) => state.chat.active_userId);
	const allChatUsers = useSelector(getAllChatUsers);
	const isActiveUserExistsInAllChat = allChatUsers.find((user) => user.uid === active_userId);
	const { _closePopupUnderPopup } = usePopups();
	const dispatch = useDispatch();
	const helper = useHelper();
	const navigator = useNavigator();
	const isPlatformIOS = useSelector(getIsPlatformIOS);

	useEffect(() => {
		if (isActiveUserExistsInAllChat) return;
		!isMobile() && _closePopupUnderPopup();
		dispatch(cancelActiveUser());
	}, [isActiveUserExistsInAllChat]);

	useEffect(() => {
		if (currentUser?.uid) {
			const isAllow = helper.showUserDetails();
			if (!isAllow) {
				navigator.navigateToMatchesPage();
			}
		}
	}, [currentUser?.uid]);
	// useEffect(() => {
	// 	dispatch(clearUnviewedNewMessages());

	// 	return () => {
	// 		dispatch(clearUnviewedNewMessages());
	// 	};
	// }, []);

	useEffect(async () => {
		if (!!isUserLoggedIn)
			await userNotificationsService.resetCountNotification(notificationUnreadTypes.chat);

		try {
			await PushNotifications.removeAllDeliveredNotifications();
			//await Badge.clear();
		} catch (error) {
			console.log(error);
		}
		//await PushNotifications.removeAllDeliveredNotifications();
	}, [isUserLoggedIn]);
	return isUserLoggedIn ? (
		<>
			<Sidebar>
				<ChatReceived tab={chatTabs.Received} />
				{isMobile() && <BottomStrip />}
			</Sidebar>
			<Content>{active_userId && <ChatContent />}</Content>
		</>
	) : (
		<Loader />
	);
};
