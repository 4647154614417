import "assets/scss/speedDate/speedDate.scss";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getUserProfile } from "redux/profile/selectors";
import speedDatingService from "services/speedDatingService";
import speedDateStatus from "consts/speedDateStatus";
import useNavigator from "hooks/useNavigator";
import DateList from "components/speedDate/DateList";
import VideoChat from "components/speedDate/VideoChat";
import { Box, CircularProgress } from "@mui/material";
import { Button } from "reactstrap";
import usePopups from "hooks/usePopups";
import HeaderVideoStatus from "components/speedDate/videoPage/HeaderVideoStatus";
import moment from "moment";
import DrawerVideoStatus from "components/speedDate/videoPage/DrawerVideoStatus";
import man from "assets/images/speedDate/manVideoHolder.png";
import woman from "assets/images/speedDate/womanVideoHolder.png";
import vdHeart from "assets/images/speedDate/vdHeart.png";
import FooterBtn from "components/speedDate/FooterBtn";

export default function SpeedDatingPage() {
	const navigate = useNavigator();
	let { urlName } = useParams();
	const { showJaaSMeetingPopup } = usePopups();

	const currentUser = useSelector(getUserProfile) || {};
	const [listDates, setListDates] = useState([]);
	const [nextDate, setNextDate] = useState(null);
	const [currentDate, setCurrentDate] = useState(null);
	const [speedData, setSpeedDate] = useState({});
	const [loading, setLoading] = useState(false);
	const [eventEndTime, setEventEndTime] = useState(false);
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [showStatusBar, setShowStatusBar] = useState(false);
	const [remainingTime, setRemainingTime] = useState();
	const [dateDuration, setDateDuration] = useState(10);

	const closeVideoDate = () => {
		setCurrentDate(null);
		setTimeout(() => {
			getCurrentAndNextTime(listDates);
		}, 1);
	};
	const toggleDrawerParent = (open = true) => {
		setIsDrawerOpen(open);
	};
	const handleFinishDate = () => {
		getCurrentAndNextTime(listDates);
	};

	const openTestJaaSMeeting = () => {
		if (!speedData?.key) return;
		setLoading(true);
		speedDatingService.getTestRoom(speedData.key).then((data) => {
			console.log(data);
			if (data.data?.success) {
				showJaaSMeetingPopup({ ...data.data, ...currentUser });
			}
			setLoading(false);
		});
	};

	const navigateAccountVideoDate = () => {
		return navigate.navigateToAccountVideoDate();
	};
	useEffect(() => {
		if (!urlName) return navigateAccountVideoDate();
		speedDatingService.getSpeedDateInfoByUrlName(urlName).then((data) => {
			if (!data) return navigateAccountVideoDate();
			const sData = Object.values(data || {})[0];

			if (
				!sData ||
				sData?.status !== speedDateStatus.matched ||
				!moment(sData?.eventDate).isSame(moment(), "day") //its not today
			)
				return navigateAccountVideoDate();

			const eventData = sData?.eventDate;
			const eventEndTime = moment(eventData).add(2, "hours"); //1 hours after the start date

			if (eventEndTime < moment().valueOf()) {
				setEventEndTime(true);
				return navigate.navigateToAccountVideoDate({ urlName: urlName });
			}
			setSpeedDate(sData);
		});
	}, []);
	const getCurrentAndNextTime = (list = []) => {
		const currentTime = moment();
		let current = null;
		let next = null;

		for (let i = 0; i < list.length; i++) {
			const dateTime = moment(list[i].time, "HH:mm").set({ second: 0 });
			const endDateTime = moment(list[i].endDateTime).set({ second: 0 });

			const isWithinTimeRange = currentTime.isBetween(dateTime, endDateTime, null, "[]");
			if (isWithinTimeRange) {
				current = list[i];

				// Find the next date
				for (let j = i + 1; j < list.length; j++) {
					if (list[j].uidMatch) {
						next = list[j];
						break;
					}
				}
				break;
			}
		}

		setCurrentDate(current);
		setNextDate(next);
	};

	useEffect(() => {
		if (listDates?.length) {
			setShowStatusBar(true);

			getCurrentAndNextTime(listDates);

			const interval = setInterval(() => {
				getCurrentAndNextTime(listDates);
			}, 5000);

			return () => clearInterval(interval);
		}
	}, [listDates]);

	return (
		<div className="speedDatePageWrapper">
			{(!!speedData?.key || !!eventEndTime) && (
				<>
					{!showStatusBar ? (
						<img className="vdHeart" src={vdHeart} />
					) : (
						<HeaderVideoStatus
							toggleDrawer={toggleDrawerParent}
							eventDate={speedData?.eventDate}
							currentDate={currentDate}
							nextDate={nextDate}
							eventEndTime={eventEndTime}
							remainingTime={remainingTime}
							closeVideoDate={closeVideoDate}
						/>
					)}
					{!!currentDate && currentDate?.uidMatch ? (
						<VideoChat
							currentDate={currentDate}
							handleFinishDate={handleFinishDate}
							setRemainingTime={setRemainingTime}
							dateDuration={dateDuration}
							speedData={speedData}></VideoChat>
					) : (
						<>
							{!nextDate && moment().isAfter(moment(speedData?.eventDate)) ? (
								<Box className="placeholderVideo">
									<Box>
										<h3>לחזרה לאתר</h3>
										<FooterBtn handleClick={navigateAccountVideoDate} text={"חזור לאתר"} />
									</Box>
								</Box>
							) : (
								<Box className="placeholderVideo">
									<img src={!currentUser?.gender ? woman : man} />
									<Box
										className="smallImg"
										style={{ padding: currentUser?.gender ? "10px 20px" : "10px" }}>
										<img src={currentUser?.gender ? woman : man} />
									</Box>

									{!!loading ? (
										<CircularProgress
											size={30}
											className="testVideoBtn text-primary"
											style={{ margin: "10px 0" }}
										/>
									) : (
										<Button
											style={{ margin: "10px 0", padding: 0, color: "#2d5086" }}
											color="none"
											type="button"
											className="testVideoBtn"
											onClick={openTestJaaSMeeting}>
											<span>{"כדי לוודא שהכל עובד חלק - מומלץ לבדוק את הוידאו"}</span>
										</Button>
									)}
								</Box>
							)}
						</>
					)}
				</>
			)}
			{!!speedData?.key && !!currentUser?.uid && (
				<>
					<Box className="bottomDrawer">
						<DrawerVideoStatus
							speedData={speedData}
							currentDate={currentDate}
							toggleDrawerParent={toggleDrawerParent}
							isDrawerOpen={isDrawerOpen}
							setListDates={setListDates}
							dateDuration={dateDuration}
							setDateDuration={setDateDuration}
							closeVideoDate={closeVideoDate}
						/>
					</Box>
				</>
			)}
		</div>
	);
}

//check camera and mic

{
	/* {!!currentUser && <div>{currentUser.name}</div>}
					{!!loading ? (
						<CircularProgress size={30} className="text-primary" style={{ margin: "10px 0" }} />
					) : (
						<Button
							style={{ margin: "10px 0", padding: 0 }}
							color="none"
							type="button"
							onClick={openTestJaaSMeeting}>
							<span>{"openTestJaaSMeeting"}</span>
						</Button>
					)} */
}
