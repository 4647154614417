import firebase from "firebase/app";
import "firebase/database";
import httpsCallable from "./httpsCallable";
import { Observable } from "rxjs";
import moment from "moment";
import { getAgeByDob } from "helpers/date";

class SpeedDatingService {
	SPEED_DATE_USERS = "speed_dating_users";
	SPEED_DATE_MATCH = "speed_dating_match";
	SPEED_DATE_EVENTS = "speed_dating_events";
	SPEED_DATE_EVENTS_USERS = "speed_dating_events_users";
	SPEED_DATE_DATE = "speed_dating_date";
	SPEED_DATE_EVENTS_STATISTICS = "speed_dating_events_statistics";
	SPEED_DATE_FEEDBACK = "speed_dating_feedback";
	SPEED_DATE_FEEDBACK_RESULT = "speed_dating_feedback_result";

	checkIfUserCanRegisterToEventByAge(speedData, currentUser) {
		const age = getAgeByDob(currentUser.dob);
		let filterAge;
		if (currentUser.gender == 1) {
			filterAge = speedData?.femaleFilters;
		} else {
			filterAge = speedData?.maleFilters;
		}

		if (filterAge.fromAge <= age && filterAge.toAge >= age) {
			return true;
		}
		return false;
	}

	async getTestRoom(speedDatingKey) {
		const result = await httpsCallable.call("getTestRoom", {
			speedDatingKey,
		});
		return result;
	}
	async getMyDating(speedDatingKey) {
		const result = await httpsCallable.call("getMyDates", {
			speedDatingKey,
		});
		return result;
	}

	async getChatVideoRoom(speedDatingKey, uidDate) {
		const result = await httpsCallable.call("getChatVideoRoom", {
			speedDatingKey,
			uidDate,
		});
		return result;
	}
	async registerUserToSpeedDating(speedDatingKey) {
		const result = await httpsCallable.call("registerUserToSpeedDating", {
			speedDatingKey,
		});
		return result;
	}
	async confirmUserSpeedDating(speedDatingKey) {
		const result = await httpsCallable.call("confirmUserSpeedDating", {
			speedDatingKey,
		});
		return result;
	}
	async cancelUserSpeedDating(speedDatingKey) {
		const result = await httpsCallable.call("cancelUserSpeedDating", {
			speedDatingKey,
		});
		return result;
	}
	async getSpeedDatingPaymentUrl(speedDatingKey) {
		const result = await httpsCallable.call("getSpeedDatingPaymentUrl", {
			speedDatingKey,
		});
		return result;
	}
	async setUserFeedback({ speedDatingKey, feedbackKey, uidDate, value }) {
		const result = await httpsCallable.call("setUserFeedback", {
			speedDatingKey,
			feedbackKey,
			uidDate,
			value,
		});
		return result;
	}

	async getFeedbackQuestions() {
		return (await firebase.database().ref(this.SPEED_DATE_FEEDBACK).once("value")).val();
	}
	async getUserEvents(uid) {
		return (
			await firebase.database().ref(this.SPEED_DATE_USERS).child(uid).child("events").once("value")
		).val();
	}
	async getUserFeedback(speedKey, uid) {
		return (
			await firebase
				.database()
				.ref(this.SPEED_DATE_FEEDBACK_RESULT)
				.child(speedKey)
				.child(uid)
				.once("value")
		).val();
	}

	async getSpeedDateInfoByUrlName(urlName) {
		return (
			await firebase
				.database()
				.ref(this.SPEED_DATE_EVENTS)
				.orderByChild("urlName")
				.equalTo(urlName)
				.once("value")
		).val();
	}

	listenToUserInSpeedDate$(id, uid) {
		return new Observable((subscriber) => {
			const ref = firebase.database().ref(this.SPEED_DATE_EVENTS_USERS).child(id).child(uid);

			const callbackFn = ref.on(
				"value",
				(snapshot) => subscriber.next(snapshot.val()),
				(error) => subscriber.error(error)
			);
			return () => ref.off("value", callbackFn);
		});
	}
	async getSpeedDateInfo(id) {
		return (await firebase.database().ref(this.SPEED_DATE_EVENTS).child(id).once("value")).val();
	}
	async getNextSpeedDate() {
		return (
			await firebase
				.database()
				.ref(this.SPEED_DATE_EVENTS)
				.orderByChild("eventDate")
				.startAt(moment().subtract(2, "hours").valueOf())
				.once("value")
		).val();
	}
	async getUserInSpeedDate(id, uid) {
		if (!id || !uid) return;
		return (
			await firebase.database().ref(this.SPEED_DATE_EVENTS_USERS).child(id).child(uid).once("value")
		).val();
	}
}

export default new SpeedDatingService();
