import i18next from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, ModalBody, Modal, ModalHeader, ButtonGroup } from "reactstrap";
import { useEffect, useState } from "react";
import _ from "lodash";

import usePopups from "hooks/usePopups";
import ImageGallery from "react-image-gallery";
import UserProfileDetails from "components/UserProfileDetails";
import UserAboutAndTags from "components/UserAboutAndTags";
import { getPopups } from "redux/popups/selectors";
import UserProfileMainInfo from "components/UserProfileMainInfo";
import UserBlocks from "components/blocks";
import { getChat } from "redux/chatReceived/selectors";
import { activeUserId } from "redux/chat/actions";
import ChatContentAndFooter from "components/chatContent/ChatContentAndFooter";
import VipRibbon from "components/VipRibbon1";
import LikeButton from "./LikeButton";
import UserDistance from "components/UserDistance";
import placeholderMan from "assets/images/emptyImages/placeholderMan.png";
import placeholderWoman from "assets/images/emptyImages/placeholderWoman.png";
import privatePicture from "assets/images/emptyImages/private.jpg";
import MessageButton from "./MessageButton";

import ShareContacts from "components/userProfileSidebar/ShareContacts";

import useNavigator from "hooks/useNavigator";
import getIfUserBirtdayInThisWeek from "helpers/getIfUserBirtdayInThisWeek";
import birthday from "assets/images/birthday/happyBirthDay.png";
import { getUserProfile } from "redux/profile/selectors";
import UserYoutubeLink from "components/UserYoutubeLink";

export default ({ userFromUrl = null }) => {
	const [displayUser, setDisplayUser] = useState(null);
	const dispatch = useDispatch();
	const { _closePopupUnderPopup, _closeSecondPopupUnderPopup, showLoginPopup } = usePopups();
	const { currentUserProfile, currentUserPromotedProfile, secondPopupUnderPopupType } =
		useSelector(getPopups);
	const navigator = useNavigator();
	const currentUser = useSelector(getUserProfile) || {};
	const isMyCardPopup = displayUser?.uid && displayUser?.uid === currentUser?.uid;

	const navigateToUserAfterLogin = () => navigator.navigateToPath(`/user/${displayUser?.uid}`);

	const handleClickActions = (e) => {
		if (!currentUser?.uid) {
			e.preventDefault();
			return showLoginPopup(navigateToUserAfterLogin);
		}
	};

	if (!displayUser) {
		if (!!userFromUrl) {
			setDisplayUser(userFromUrl);
		} else {
			setDisplayUser(currentUserPromotedProfile || currentUserProfile);
		}
	}

	const [startIndex] = useState(
		_.isObject(displayUser?.pictures) && !_.isEmpty(displayUser?.pictures)
			? Object.keys(displayUser?.pictures).findIndex((val) => val === displayUser?.mainPictureName)
			: 0
	);
	const defaultImage = displayUser?.gender ? placeholderWoman : placeholderMan;

	const images =
		_.isObject(displayUser?.pictures) && !_.isEmpty(displayUser?.pictures)
			? Object.keys(displayUser?.pictures)?.map((picName) => ({
					original: displayUser?.pictures[picName].url,
					thumbnail: displayUser?.pictures[picName].url,
			  }))
			: [
					{
						original: displayUser?.isPicturesPrivate ? privatePicture : defaultImage,
						thumbnail: displayUser?.isPicturesPrivate ? privatePicture : defaultImage,
					},
			  ];

	const chatID = useSelector(getChat(displayUser?.uid));

	const goBack = () => {
		if (!!userFromUrl) {
			navigator.navigateToHomePage();
		} else {
			secondPopupUnderPopupType ? _closeSecondPopupUnderPopup() : _closePopupUnderPopup();
		}
	};

	useEffect(() => {
		if (!chatID) return;
		dispatch(activeUserId(displayUser?.uid, chatID));
	}, [chatID]);

	return (
		<Modal isOpen={true} toggle={goBack} className="bigSlideDialog cardUserDialog">
			<Button
				color="none"
				type="button"
				onClick={goBack}
				className="nav-btn closeButton"
				id="user-profile-hide">
				<i className="ri-close-line"></i>
			</Button>
			<ModalHeader>
				<UserProfileMainInfo className="userPopupHeaderInfo" profile={displayUser} />
			</ModalHeader>
			<ModalBody>
				<div className="galleryChat">
					<div className="wrapImageGallery">
						{!!getIfUserBirtdayInThisWeek(displayUser?.dob) && (
							<div
								style={{
									position: "absolute",
									left: "10px",
									top: "10px",
									zIndex: 1,
								}}>
								<img src={birthday} alt="birthday" />
							</div>
						)}
						{displayUser?.isSubscribed && <VipRibbon />}
						{(!!displayUser?.distance || displayUser?.distance == "0") && (
							<UserDistance distance={displayUser.distance} />
						)}
						{!_.isNil(images) && (
							<ImageGallery
								items={images}
								showThumbnails={false}
								showPlayButton={false}
								autoPlay={true}
								slideInterval={6000}
								useTranslate3D={false}
								stopPropagation={true}
								startIndex={startIndex}
								showBullets={images.length > 1}
							/>
						)}
						{!isMyCardPopup && (
							<ButtonGroup onClick={handleClickActions}>
								<LikeButton user={displayUser} />
							</ButtonGroup>
						)}
					</div>

					{!isMyCardPopup && (
						<>
							{chatID ? (
								<div className="user-chat w-100 user-chat-show">
									<span className="titleForChat">
										{i18next.t("Chat Message.Messages between you and", {
											name: displayUser?.name,
										})}
									</span>
									<div className="d-lg-flex">
										<div className="w-100 slide">
											<ChatContentAndFooter isFromCardUserModal={true} />
										</div>
									</div>
								</div>
							) : (
								<div className="wrapBtnSend" onClick={handleClickActions}>
									<MessageButton user={displayUser} chatID={chatID} />
								</div>
							)}
						</>
					)}
				</div>
				<div className="userDesc">
					<div className="innerUserDesc">
						<UserAboutAndTags profile={displayUser} isCardModal={true} />
						<UserYoutubeLink profile={displayUser} isCardModal={true} />

						<UserProfileDetails profile={displayUser} />
						{!isMyCardPopup && (
							<ShareContacts colleagueId={displayUser?.uid} colleague={displayUser} />
						)}
					</div>
					{!isMyCardPopup && !!currentUser?.uid && <UserBlocks profile={displayUser} />}
				</div>
			</ModalBody>
		</Modal>
	);
};
