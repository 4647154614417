import { createSelector } from "reselect";
import { isEmpty, pick } from "lodash";

import { GeneralPendingFields, ProfilePendingFields } from "consts/pendingFields";
import { getIsNewUser, isUserLoggedIn } from "redux/auth/selectors";
import UserStatus from "consts/userStatus";
import ApiRequestStatus from "consts/apiRequestStatus";
import MessagesSettings from "consts/messagesSettings";
import PendingStatus from "consts/pendingStatus";
import { getLastMassLetterCencelDate } from "redux/massLetter/selectors";
import moment from "moment";
import { getUserProfile } from "redux/profile/selectors";

export const getGeneral = (state) => state.general;

export const getUserQuotaStatus = createSelector(
	getGeneral,
	(general) => !!general?.userGeneral?.isExamUser || !!general?.userGeneral?.isAutoExamUser
);

export const getUserGeneralStatus = createSelector(
	getGeneral,
	(general) => general?.userGeneral?.status
);

export const getCreateDateTime = createSelector(
	getGeneral,
	(general) => general?.userGeneral?.createDateTime
);

export const getMessagesSettings = createSelector(getGeneral, (general) => {
	return general?.userGeneral?.messages_settings;
});

export const getAllowSendMassLetterMessage = createSelector(
	getMessagesSettings,
	(messagesSettings) => {
		return (
			messagesSettings && messagesSettings[MessagesSettings.allowSendMassLetterMessage] !== false
		);
	}
);

export const getUserGeneral = createSelector(getGeneral, (general) => general?.userGeneral);

export const getInternalID = createSelector(
	getUserGeneral,
	(userGeneral) => userGeneral?.internalID
);

const getPending = createSelector(getUserGeneral, (userGeneral) => userGeneral?.pendings || {});

export const getEditProfilePending = createSelector(getPending, (pendings) =>
	pick(pendings || {}, ProfilePendingFields)
);

export const getGeneralPending = createSelector(getPending, (pendings) =>
	pick(pendings || {}, GeneralPendingFields)
);

export const getGeneralRejectedPending = createSelector(getGeneralPending, (generalPendings) =>
	Object.keys(generalPendings).filter(
		(key) => generalPendings[key].status === PendingStatus.Rejected
	)
);

export const getRejectedEditProfilePending = createSelector(getUserGeneral, (userGeneral) => {
	const editProfilePending = pick(userGeneral?.pendings || {}, ProfilePendingFields);
	return Object.keys(editProfilePending).filter(
		(key) => editProfilePending[key].status === PendingStatus.Rejected
	);
});

const getUserGeneralPublic = createSelector(getUserGeneral, (userGeneral) => userGeneral?.public);

const getUserGeneralPublicSettings = createSelector(
	getUserGeneralPublic,
	(userGeneralPublic) => userGeneralPublic?.settings
);

const getUserGeneralMassLetter = createSelector(getUserGeneralPublic, (userGeneralPublic) => {
	return userGeneralPublic?.massletter;
});

const canOpenMassLetterPopup = (user) => {
	if (!user || !!isEmpty(user || {})) return false;

	if (
		user?.pictures &&
		!isEmpty(user?.pictures) &&
		!!user?.mainPictureName &&
		user?.isPicturesPrivate !== true &&
		!!user?.hasCompleteRegistration &&
		(!user?.manualRating || user?.manualRating >= 3)
	) {
		return true;
	} else {
		return false;
	}
};

export const getShowMassLetterPopup = createSelector(
	getUserGeneralMassLetter,
	getUserProfile,
	(userGeneralMassLetter, userProfile) => {
		if (!!isEmpty(userProfile || {})) return false;

		return (
			canOpenMassLetterPopup(userProfile) &&
			userGeneralMassLetter?.showPopup &&
			userGeneralMassLetter?.showPopupCount < 2
		);
	}
);

export const getThreeMonthsPassedMassLetter = createSelector(
	getLastMassLetterCencelDate,
	(lastMassLetterCencelDate) => {
		const isThreeMonthsPassed =
			moment().diff(moment(lastMassLetterCencelDate ?? Date.now()), "months") >= 3;
		return !!isThreeMonthsPassed;
	}
);

export const getUserCommunications = createSelector(
	getUserGeneralPublicSettings,
	(userGeneralPublic) => userGeneralPublic?.communication || {}
);
export const getAllowShowInTelegram = createSelector(
	getUserGeneralPublicSettings,
	(userGeneralPublic) => userGeneralPublic?.telegram?.allowShowInTelegram || false
);

export const getUserNotifications = createSelector(
	getUserGeneralPublicSettings,
	(userGeneralPublic) => userGeneralPublic?.notifications || {}
);

export const isUserGeneralApiStatusSuccessfully = createSelector(
	getGeneral,
	(general) => general?.userGeneralApiStatus === ApiRequestStatus.SUCCESSFULLY
);

export const getIstUserGeneralStatusBlocked = createSelector(
	isUserGeneralApiStatusSuccessfully,
	getUserGeneralStatus,
	getIsNewUser,
	(isUserGeneralLoadedSuccessfully, userStatus, isNewUser) =>
		!isNewUser &&
		isUserGeneralLoadedSuccessfully &&
		userStatus &&
		userStatus !== UserStatus.PendingApproval &&
		userStatus !== UserStatus.Approved
);

export const shouldNavigateProfileBlockedInPage = createSelector(
	isUserLoggedIn,
	getIstUserGeneralStatusBlocked,
	(_isUserLoggedIn, _istUserGeneralStatusBlocked) => _isUserLoggedIn && _istUserGeneralStatusBlocked
);

export const getMessagesFilter = createSelector(getUserGeneralPublic, (userGeneralPublic) => {
	return userGeneralPublic?.messagesFilter || {};
});
