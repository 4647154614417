import React, { useEffect, useRef, useState } from "react";
import { Button, Input, Row, Col, Label, Form } from "reactstrap";
import "emoji-mart/css/emoji-mart.css";
import i18next from "i18next";

import ChatMetaService from "services/chatMeta";
import usePopups from "hooks/usePopups";
import SendTemplateMessageButton from "components/search/card/SendTemplateMessageButton";
import isMobile from "helpers/isMobile";
import templateMsgService from "services/templateMsg.service";
import getIfUserBirtdayInThisWeek from "helpers/getIfUserBirtdayInThisWeek";
import _ from "lodash";
import AudioChat from "./AudioChat";
import AudioChatApp from "./AudioChatApp";
import { checkRelevantQuota } from "helpers/quota";
import { useSelector } from "react-redux";
import { getUserQuotaStatus } from "redux/general/selectors";
import { getIsPlatformWeb } from "redux/init/selectors";
import { getUserProfile } from "redux/profile/selectors";

export default ({ chatID, userUid, gender, onSend, user }) => {
	const currentUser = useSelector(getUserProfile);

	const isPlatformWeb = useSelector(getIsPlatformWeb);
	const [message, setMessage] = useState("");
	const [audioMessageMode, setAudioMessageMode] = useState(false);
	const [typing, setTyping] = useState(false);
	const [inputClass, setInputClass] = useState("");
	const [templates, setTemplates] = useState([]);
	const inpRef = useRef();
	const userQuotaStatus = useSelector(getUserQuotaStatus);

	const { showQrcodePopup, showQuotaPopup } = usePopups();

	useEffect(() => {
		if (userUid && chatID) {
			if (message) {
				ChatMetaService.updateTyping(userUid, chatID, true);
				setTyping(true);
			} else if (typing) {
				ChatMetaService.updateTyping(userUid, chatID, null);
			}
		}
	}, [message, userUid, chatID]);

	useEffect(() => {
		setInputClass(inpRef.current.clientHeight > 50 ? "multiRows" : "");
	}, [message]);

	const handleChange = (e) => {
		setMessage(e.target.value);
	};

	const handleSendMessage = async () => {
		if (userQuotaStatus && (await checkRelevantQuota())) return showQuotaPopup();

		const trimMessage = message.trim();
		if (trimMessage !== "") {
			onSend(trimMessage);
			setMessage("");
		}
	};

	const saveAudioMessage = async (url) => {
		if (userQuotaStatus && (await checkRelevantQuota())) return showQuotaPopup();

		onSend(url, true);
	};

	const onKeyPress = (e) => {
		e.key === "Enter" && handleSendMessage();
	};

	const openAudioMessageArea = () => {
		setAudioMessageMode(true);
	};
	const closeAudioMessageArea = () => {
		setAudioMessageMode(false);
	};

	useEffect(() => {
		templateMsgService.fetchTemplateMsg().then((data) => {
			if (!!data && !_.isEmpty(data)) {
				const newTemplates = Object.values(data)
					.map((item) => {
						let newTemp = item;
						newTemp.messageMale = item.messageMale.replace("[name]", user?.name);
						newTemp.messageFemale = item.messageFemale.replace("[name]", user?.name);
						return newTemp;
					})
					.filter((item) => {
						if (
							!item.active ||
							(item.type == "Birthday" && !getIfUserBirtdayInThisWeek(user?.dob))
						) {
							return false;
						}
						return true;
					});

				const sortedTemplates = _.sortBy(newTemplates, [
					(item) => {
						switch (item.type) {
							case "Birthday":
								return 1;
							case "Holiday":
								return 2;
							default:
								return 3;
						}
					},
					"priority",
				]);

				setTemplates(sortedTemplates);
			}
		});
	}, []);

	return (
		<React.Fragment>
			<div
				className={`p-3 p-lg-4 border-top mb-0 chatFooter${audioMessageMode ? " audioMode" : ""}`}>
				{!audioMessageMode && (
					<Row noGutters className={"alignCenterHeight"}>
						<Col xs="auto" style={{ position: "relative" }}>
							<span
								style={{
									position: "absolute",
									top: "-10px",
									fontWeight: "500",
									fontSize: "12px",
									background: "#F65368",
									borderRadius: "20px",
									padding: "2px 3px",
									right: "-10px",
									zIndex: "1",
									color: "#fff",
								}}>
								חדש!
							</span>
							<ul className="list-inline mb-0 ms-0">
								<li className="list-inline-item input-file record">
									<Label
										id="jsmartRecord"
										tabIndex="0"
										onClick={
											false
												? () => showQrcodePopup(i18next.t("Recording Qrcode Text"))
												: () => openAudioMessageArea()
										}
										//</li>onClick={() => openAudioMessageArea()}
										className="btn btn-link text-decoration-none font-size-16 btn-lg waves-effect jsmartRecord"></Label>
								</li>
							</ul>
						</Col>
						<Col>
							<div className="me-1 inputAndQuote grow-wrap" data-replicated-value={message}>
								<Input
									innerRef={inpRef}
									type="textarea"
									value={message}
									onChange={handleChange}
									onKeyPress={onKeyPress}
									rows={1}
									className={`form-control bg-light border-light ${inputClass}`}
									placeholder={i18next.t(`Chat Message.Type message_${gender}`)}
								/>
								<SendTemplateMessageButton
									templates={templates}
									user={user}
									isFromCardUserSidebar={true}
									usePopover={!isMobile() ? true : false}
								/>
							</div>
						</Col>
						<Col xs="auto">
							<div className="chat-input-links ms-md-2">
								<ul className="list-inline mb-0 ms-0">
									<li className="list-inline-item">
										<Button
											onClick={handleSendMessage}
											color=""
											className="font-size-16 btn-lg chat-send waves-effect waves-light jsmartChatBtn">
											<span>{i18next.t(`Chat Message.Send Message_${gender}`)}</span>
										</Button>
									</li>
								</ul>
							</div>
						</Col>
					</Row>
				)}
				{!!audioMessageMode && (
					<Row noGutters className={"alignCenterHeight"}>
						<Col>
							{!!isPlatformWeb ? (
								<AudioChat
									userUid={currentUser.uid}
									back={closeAudioMessageArea}
									save={(url) => {
										saveAudioMessage({ url });
									}}></AudioChat>
							) : (
								<AudioChatApp
									userUid={currentUser.uid}
									back={closeAudioMessageArea}
									save={(url) => {
										saveAudioMessage({ url });
									}}></AudioChatApp>
							)}
						</Col>
					</Row>
				)}
			</div>
		</React.Fragment>
	);
};
