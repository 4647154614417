import "assets/scss/popups/chanel.scss";
import { Button, ModalBody, Modal, ModalHeader, ModalFooter } from "reactstrap";
import usePopups from "hooks/usePopups";
import { useEffect, useState } from "react";
import ChanelHeader from "components/chanel/ChanelHeader";
import ChanelBody from "components/chanel/ChanelBody";
import userNotificationsService from "services/unreadNotification.service";
import notificationUnreadTypes from "consts/notificationUnreadTypes";

export default () => {
	const { closePopup } = usePopups();

	useEffect(() => {
		return userNotificationsService.resetCountNotification(notificationUnreadTypes.broadcast);
	}, []);
	return (
		<Modal isOpen={true} toggle={closePopup} className={`simpleDialog chanelWrapper`}>
			<ModalHeader>
				<ChanelHeader closePopup={closePopup} />
			</ModalHeader>

			<ModalBody>
				<ChanelBody />
			</ModalBody>
			{/* <ModalFooter>
				<Button
					outline
					size="sm"
					color="primary"
					onClick={closePopup}
					className="btn-rounded littleBtn">
					{data?.footerBtnText || "הבנתי"}
				</Button>
			</ModalFooter> */}
		</Modal>
	);
};
