import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useEffect, useRef } from "react";

import {
	getCurrentPopupType,
	getPopupUnderPopupType,
	getSecondPopupUnderPopupType,
} from "redux/popups/selectors";
import PopupsTypes from "consts/popupsTypes";
import UnmatchUserModal from "./userBlocks/unmatchUserModal";
import BlockUserModal from "./userBlocks/blockUserModal";
import ReportUserModal from "./userBlocks/reportUserModal";
import ReportQuestiontUserModal from "./userBlocks/reportQuestiontUserModal";
import UserSuccessfullyBlockedModal from "./userBlocks/userSuccessfullyBlockedModal";
import ThanksReportUserModal from "./userBlocks/thanksReportUserModal";
import GalleryModal from "./GalleryModal";
import QrcodePopup from "./QrcodePopup";
import UpdatesSavedSuccessfullyModal from "./account/UpdatesSavedSuccessfullyModal";
import ContactFormSentSuccessfullyModal from "./account/ContactFormSentSuccessfullyModal";
import FreezeAccountModal from "./account/FreezeAccountModal";
import FreezeAccountSuccessfullyModal from "./account/FreezeAccountSuccessfullyModal";
import DeleteAccountModal from "./modals/DeleteAccountModal";
import DeleteAccountSelectReasonModal from "./modals/DeleteAccountSelectReasonModal";
import DeleteAccountAddMessageModal from "./modals/DeleteAccountAddMessageModal";
import DeleteAccountSuccessfullyModal from "./modals/DeleteAccountSuccessfullyModal";
import LanguageSwitchModal from "./account/AdditionalSettings/LanguageSwitchModal";
import DeleteImageModal from "./account/DeleteImageModal";
import CropImageModal from "./account/CropImageModal";
import CardUserModal from "./search/card/CardUserModal";
import UserProfileSidebar from "./userProfileSidebar";
import SubscriptionModal from "./SubscriptionModal";
import SubscriptionDealModal from "./SubscriptionModal/deal";
import SubscriptionSuccessfullyPopup from "./SubscriptionModal/SubscriptionSuccessfullyPopup";
import UnblockQuestionModal from "./account/AccountSettings/UnblockQuestionModal";
import DeleteLikedMeUsersModal from "./likedMe/DeleteLikedMeUsersModal";
import DeleteChatsUsersModal from "./chatSideBar/DeleteChatsUsersModal";
import MassletterModal from "components/modals/MassletterModal";
import MassletterSentModal from "components/modals/MassletterSentModal";
import ChatReceivedFilterModal from "components/modals/ChatReceivedFilterModal";
import SendMessageModal from "components/modals/SendMessageModal";
import SendTemplateMessageModal from "components/modals/SendTemplateMessageModal";
import BlurMessageModal from "./modals/BlurMessageModal";
import NonSubscriberToNonSubscriberModal from "./modals/NonSubscriberToNonSubscriberModal";
import NonSubscriberToSubscriberModal from "./modals/NonSubscriberToSubscriberModal";
import SubscriberLimitMessagesModal from "./modals/SubscriberLimitMessagesModal";
import NonSubscriberLimitMessagesModal from "./modals/NonSubscriberLimitMessagesModal";
import MessageSuccessfullySentModal from "./modals/MessageSuccessfullySentModal";
import NotApprovedMessagePopup from "./modals/NotApprovedMessagePopup";
import usePopups from "hooks/usePopups";
import isMobile from "helpers/isMobile";
import TagAddedSuccessfullyModal from "./modals/TagAddedSuccessfullyModal";
import ContactFromFeedbackButtonModal from "./modals/ContactFromFeedbackButtonModal";
import UnsubscribeCancelModal from "./modals/UnsubscribeCancelModal";
import UnsubscribeErrorModal from "./modals/UnsubscribeErrorModal";
import UnsubscribeSuccessfullySavedModal from "./modals/UnsubscribeSuccessfullySavedModal";
import BigImageModal from "./modals/BigImageModal";
import OpenMobileStoreModal from "./modals/OpenMobileStoreModal";
import VerifiedEmailModal from "./modals/VerifiedEmailModal";
import ExplanationEffortModal from "./modals/ExplanationEffortModal";
import EffortSuccessfullyModal from "./modals/EffortSuccessfullyModal";
import LowQualityImageModal from "./modals/LowQualityImageModal";
import FittedMessageModal from "./modals/FittedMessageModal";
import LoginModal from "./modals/LoginModal";
import JaaSMeetingModal from "./modals/JaaSMeetingModal";
import ForceUploadImageModal from "./modals/ForceUploadImageModal";
import ForceShareFriendsModal from "./modals/ForceShareFriendsModal";
import ShareLocationsMobile from "./modals/ShareLocationsMobile";
import DeleteCommentModal from "./modals/DeleteCommentModal";
import QuotaIsOverModal from "./modals/QuotaIsOverModal";
import EnteredMatchModal from "./modals/EnteredMatchModal";
import DynamicModal from "./modals/DynamicModal";
import BuyWithTokenModal from "./modals/BuyWithTokenModal";
import CancelVideoDateRegisterModal from "./modals/CancelVideoDateRegisterModal";
import LogoutModal from "./modals/LogoutModal";
import ReportVideoDateModal from "./modals/ReportVideoDateModal";
import BroadcastChanel from "./modals/BroadcastChanel";
import PhoneNotificationSettingsModal from "./modals/PhoneNotificationSettingsModal";
import NonSubscriberCantSendMessageModal from "./modals/NonSubscriberCantSendMessageModal";
import SaleSubscriptionModal from "./SubscriptionModal/SaleModal";
import ApproveShowInTelegramModal from "./modals/ApproveShowInTelegramModal";
import PromotionsSaleModal from "./promotionsSale/PromotionsSaleModal";

export default () => {
	const currentPopupType = useSelector(getCurrentPopupType);
	const popupUnderPopupType = useSelector(getPopupUnderPopupType);
	const secondPopupUnderPopupType = useSelector(getSecondPopupUnderPopupType);
	const { closePopup, _closePopupUnderPopup, _closeSecondPopupUnderPopup } = usePopups();
	const history = useHistory();
	const saveCurrentPopupType = useRef();
	const saveCurrentPopupUnderPopupType = useRef();

	useEffect(() => {
		saveCurrentPopupType.current = currentPopupType;
		saveCurrentPopupUnderPopupType.current = popupUnderPopupType;

		if (!!saveCurrentPopupType.current && saveCurrentPopupType.current !== PopupsTypes.None) {
			history.block((location, action) => {
				if (action === "POP") {
					//POP = "history.goBack"
					if (
						history.location.pathname === "/messages" &&
						!isMobile() &&
						saveCurrentPopupUnderPopupType.current
					) {
						if (
							saveCurrentPopupUnderPopupType.current == saveCurrentPopupType.current ||
							saveCurrentPopupType.current == PopupsTypes.None
						) {
							//if no popup - go back in history
							return history.go(-1);
						}

						//dont close user side bar in messages page
						if (saveCurrentPopupType.current) {
							_closeSecondPopupUnderPopup();
							closePopup();
							return false;
						}
						return true;
					}
					if (saveCurrentPopupType.current || saveCurrentPopupUnderPopupType.current) {
						//dont go back if there is a popup open
						if (saveCurrentPopupUnderPopupType.current == saveCurrentPopupType.current) {
							_closeSecondPopupUnderPopup();
							_closePopupUnderPopup();
							closePopup();
							return false;
						}

						if (
							saveCurrentPopupUnderPopupType.current &&
							!!saveCurrentPopupType.current &&
							saveCurrentPopupType.current !== PopupsTypes.None
						) {
							closePopup();
							return false;
						}

						if (!!saveCurrentPopupType.current) {
							_closePopupUnderPopup();
							return false;
						}

						return true;
					} else {
						return true;
					}
				}
			});
		}
	}, [currentPopupType, popupUnderPopupType]);

	return (
		<>
			{currentPopupType === PopupsTypes.UnmatchUser && <UnmatchUserModal />}
			{currentPopupType === PopupsTypes.BlockUser && <BlockUserModal />}
			{currentPopupType === PopupsTypes.ReportUser && <ReportUserModal />}
			{currentPopupType === PopupsTypes.QuestionReport && <ReportQuestiontUserModal />}
			{currentPopupType === PopupsTypes.UserSuccessfullyBlocked && <UserSuccessfullyBlockedModal />}
			{currentPopupType === PopupsTypes.ThanksReportUser && <ThanksReportUserModal />}
			{currentPopupType === PopupsTypes.Gallery && <GalleryModal />}
			{currentPopupType === PopupsTypes.QRCode && <QrcodePopup />}
			{currentPopupType === PopupsTypes.UpdatesSavedSuccessfully && (
				<UpdatesSavedSuccessfullyModal />
			)}
			{currentPopupType === PopupsTypes.ContactFormSentSuccessfully && (
				<ContactFormSentSuccessfullyModal />
			)}
			{currentPopupType === PopupsTypes.FreezeAccountPopup && <FreezeAccountModal />}
			{currentPopupType === PopupsTypes.FreezeAccountSuccessfullyPopup && (
				<FreezeAccountSuccessfullyModal />
			)}
			{currentPopupType === PopupsTypes.DeleteAccountPopup && <DeleteAccountModal />}
			{currentPopupType === PopupsTypes.DeleteAccountSelectReasonPopup && (
				<DeleteAccountSelectReasonModal />
			)}
			{currentPopupType === PopupsTypes.DeleteAccountAddMessagePopup && (
				<DeleteAccountAddMessageModal />
			)}
			{currentPopupType === PopupsTypes.DeleteAccountSuccessfullyPopup && (
				<DeleteAccountSuccessfullyModal />
			)}
			{currentPopupType === PopupsTypes.LanguageSwitchPopup && <LanguageSwitchModal />}
			{currentPopupType === PopupsTypes.DeleteImagePopup && <DeleteImageModal />}
			{currentPopupType === PopupsTypes.CropImagePopup && <CropImageModal />}
			{currentPopupType === PopupsTypes.SubscriptionModal && <SubscriptionModal />}
			{currentPopupType === PopupsTypes.SubscriptionDealModal && <SubscriptionDealModal />}
			{currentPopupType === PopupsTypes.SubscriptionSuccessfullyPopup && (
				<SubscriptionSuccessfullyPopup />
			)}
			{currentPopupType === PopupsTypes.UnblockUserPopup && <UnblockQuestionModal />}
			{currentPopupType === PopupsTypes.DeleteLikedMeUsersPopup && <DeleteLikedMeUsersModal />}
			{currentPopupType === PopupsTypes.DeleteChatsPopup && <DeleteChatsUsersModal />}
			{currentPopupType === PopupsTypes.MassletterPopup && <MassletterModal />}
			{currentPopupType === PopupsTypes.MassletterSentPopup && <MassletterSentModal />}
			{currentPopupType === PopupsTypes.ChatReceivedFilterPopup && <ChatReceivedFilterModal />}
			{currentPopupType === PopupsTypes.SendMessagePopup && <SendMessageModal />}
			{currentPopupType === PopupsTypes.SendTemplateMessagePopup && <SendTemplateMessageModal />}
			{currentPopupType === PopupsTypes.BlurMessagePopup && <BlurMessageModal />}
			{currentPopupType === PopupsTypes.NotApprovedMessagePopup && <NotApprovedMessagePopup />}
			{currentPopupType === PopupsTypes.NonSubscriberToSubscriberPopup && (
				<NonSubscriberToSubscriberModal />
			)}
			{currentPopupType === PopupsTypes.NonSubscriberToNonSubscriberPopup && (
				<NonSubscriberToNonSubscriberModal />
			)}
			{currentPopupType === PopupsTypes.NonSubscriberCantSendMessagesPopup && (
				<NonSubscriberCantSendMessageModal />
			)}
			{currentPopupType === PopupsTypes.SubscriberLimitMessagesPopup && (
				<SubscriberLimitMessagesModal />
			)}
			{currentPopupType === PopupsTypes.NonSubscriberLimitMessagesPopup && (
				<NonSubscriberLimitMessagesModal />
			)}
			{currentPopupType === PopupsTypes.MessageSuccessfullySentPopup && (
				<MessageSuccessfullySentModal />
			)}
			{currentPopupType === PopupsTypes.TagAddedSuccessfullyPopup && <TagAddedSuccessfullyModal />}
			{currentPopupType === PopupsTypes.ContactFromFeedbackButtonPopup && (
				<ContactFromFeedbackButtonModal />
			)}
			{currentPopupType === PopupsTypes.UnsubscribeSuccessfullySavedPopup && (
				<UnsubscribeSuccessfullySavedModal />
			)}
			{currentPopupType === PopupsTypes.UnsubscribeErrorPopup && <UnsubscribeErrorModal />}
			{currentPopupType === PopupsTypes.UnsubscribeCancelPopup && <UnsubscribeCancelModal />}
			{popupUnderPopupType === PopupsTypes.CardUserModal && <CardUserModal />}
			{secondPopupUnderPopupType === PopupsTypes.CardUserModal && <CardUserModal />}
			{popupUnderPopupType === PopupsTypes.UserProfileSideBar && <UserProfileSidebar />}
			{currentPopupType === PopupsTypes.BigImage && <BigImageModal />}
			{currentPopupType === PopupsTypes.LowQualityImage && <LowQualityImageModal />}
			{currentPopupType === PopupsTypes.MobileStoreModal && <OpenMobileStoreModal />}
			{currentPopupType === PopupsTypes.ShareLocationsMobile && <ShareLocationsMobile />}
			{currentPopupType === PopupsTypes.VerifiedEmail && <VerifiedEmailModal />}
			{currentPopupType === PopupsTypes.ExplanationEffort && <ExplanationEffortModal />}
			{currentPopupType === PopupsTypes.EffortSuccessfully && <EffortSuccessfullyModal />}
			{currentPopupType === PopupsTypes.FittedMessagePopup && <FittedMessageModal />}
			{currentPopupType === PopupsTypes.LoginPopup && <LoginModal />}
			{currentPopupType === PopupsTypes.JaaSMeeting && <JaaSMeetingModal />}
			{currentPopupType === PopupsTypes.ForceUploadImage && <ForceUploadImageModal />}
			{currentPopupType === PopupsTypes.ForceShareFriends && <ForceShareFriendsModal />}
			{currentPopupType === PopupsTypes.DeleteCommentPopup && <DeleteCommentModal />}
			{currentPopupType === PopupsTypes.QuotaIsOver && <QuotaIsOverModal />}
			{currentPopupType === PopupsTypes.EnteredMatchPopup && <EnteredMatchModal />}
			{currentPopupType === PopupsTypes.ApproveShowInTelegramPopup && (
				<ApproveShowInTelegramModal />
			)}

			{currentPopupType === PopupsTypes.DynamicModal && <DynamicModal />}
			{currentPopupType === PopupsTypes.BroadcastChanel && <BroadcastChanel />}

			{currentPopupType === PopupsTypes.BuyWithToken && <BuyWithTokenModal />}
			{currentPopupType === PopupsTypes.CancelVideoDateRegister && <CancelVideoDateRegisterModal />}
			{currentPopupType === PopupsTypes.ReportVideoDate && <ReportVideoDateModal />}
			{currentPopupType === PopupsTypes.LogoutPopup && <LogoutModal />}
			{currentPopupType === PopupsTypes.PhoneNotificationSettings && (
				<PhoneNotificationSettingsModal />
			)}
			{currentPopupType === PopupsTypes.SaleSubscriptionModal && <SaleSubscriptionModal />}
			{currentPopupType === PopupsTypes.PromotionsSaleModal && <PromotionsSaleModal />}
		</>
	);
};
