import i18next from "i18next";
import { Nav } from "reactstrap";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";

import profileImg from "assets/images/icons/userListB.svg";
import settingsImg from "assets/images/icons/settingsB.svg";
import NavLinkItem from "components/NavLinkItem";
import SideBarSubLinkItem from "./SideBarSubLinkItem";
import { getUserSubscriptionType } from "redux/subscription/selectors";
import Subscription from "consts/subscription";
import { getBlockedUsers } from "redux/blocks/selectors";
import { Box } from "@mui/material";
import newVideoDating from "assets/images/speedDate/newVideoDating.png";
import { getUserProfile } from "redux/profile/selectors";

export default () => {
	const history = useHistory();
	const currentPath = history.location.pathname;
	const subscriptionType = useSelector(getUserSubscriptionType);
	const blockedUsers = useSelector(getBlockedUsers);
	const userProfile = useSelector(getUserProfile);

	const _sideBarItems = [
		{
			content: i18next.t("Pages.Edit Profile"),
			className: "profileIcon",
			to: "/account/editProfile",
		},
		{
			content: i18next.t("Pages.Edit Pictures"),
			className: "imagesIcon",
			to: "/account/pictures",
		},
		{
			content: i18next.t("Pages.How I Look"),
			className: "profileIcon",
			to: "#",
			openMyProfile: true,
		},
		// {
		// 	content: (
		// 		<Box style={{ position: "relative", right: " -2.6rem" }}>
		// 			<img src={newVideoDating} alt="new video dating" />
		// 		</Box>
		// 	),
		// 	className: "",
		// 	to: "/account/videoDate",
		// },
		{
			content: i18next.t("Pages.System Settings"),
			icon: settingsImg,
			items: [
				{
					content: i18next.t("Pages.Account Settings"),
					className: "pl-4",
					to: "/account/settings",
				},
				...(subscriptionType === Subscription.PAID
					? [
							{
								content: i18next.t("Pages.Account Subscription"),
								className: "pl-4",
								to: "/account/subscription",
							},
					  ]
					: []),
				{
					content: i18next.t("Pages.Blocked Profiles"),
					className: "pl-4",
					to: "/account/blocked",
				},
				{
					content: i18next.t("Pages.Additional Settings"),
					className: "pl-4",
					to: "/account/more-settings",
				},
			],
		},
		{
			content: i18next.t("Pages.Contact"),
			className: "contactIcon",
			to: "/account/contact",
		},
		{
			content: i18next.t("Pages.Terms"),
			className: "takanonIcon",
			to: "/account/terms",
		},
		{
			content: i18next.t("Pages.Sign Out"),
			className: "signOutIcon",
			isLogout: true,
			to: "/logout",
		},
	];

	if (!!userProfile?.isTester) {
		_sideBarItems.push({
			content: (
				<Box style={{ position: "relative", right: " -2.6rem" }}>
					<img src={newVideoDating} alt="new video dating" />
				</Box>
			),
			className: "",
			to: "/account/videoDate",
		});
	}

	return (
		<div className="side-menu ">
			<Nav vertical className="list-unstyled pb-3">
				{_sideBarItems.map((item, index) => {
					if (item.to) {
						return (
							<NavLinkItem key={index} currentPath={currentPath} {...item}>
								{item.content}
							</NavLinkItem>
						);
					}
					return <SideBarSubLinkItem key={index} currentPath={currentPath} {...item} />;
				})}
			</Nav>
		</div>
	);
};
