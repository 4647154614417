import "firebase/database";
import httpsCallable from "./httpsCallable";

class PurchaseService {
	BASE_REF = "users_purchase_start";

	async updatePurchaseIsStart(type) {
		const result = await httpsCallable.call("updatePurchaseIsStart", { type });
		return result;
	}
}

export default new PurchaseService();
