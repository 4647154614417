import "firebase/database";
import httpsCallable from "./httpsCallable";
import AnonymousCallService from "services/anonymousCall";

class AlternativeLoginService {
	//	BASE_REF = "articles";

	async getTokenLoginByCode(phone, code) {
		const result = await AnonymousCallService.call("getTokenByCode", { code, phone });

		return result;
	}

	async authWithCode(phone) {
		const result = await AnonymousCallService.call("authWithCode", { phone });

		return result;
	}
}

export default new AlternativeLoginService();
