export default () => {
	return (
		<>
			<p dir="rtl" style={{ textAlign: "justify" }}>
				<span style={{ fontSize: "11pt" }}>
					מסמך זה מהווה הסכם בינך לבין שליש גן עדן בע"מ (ח.פ 514623446) טלפון: 086519660, פקס:
					086217553 דואר אלקטרוני info@shlish.co.il מען למכתבים: גולדה מאיר 3 נס ציונה (להלן:"מפעילת
					האתר").
				</span>
			</p>
			<p dir="rtl" style={{ textAlign: "justify" }}>
				<span style={{ fontSize: "11pt" }}>
					בעת אישור התקנון הינך מצהיר בזאת כי קראת את התקנון בשלמותו והינך מתחייב לעמוד בכל תנאי
					האתר. יש לציין שהוראת תקנון זה יגברו על כל פרסום או מודעה במידה ויהיו כאלה באתר. במידה
					ואינך מסכים לסעיף כלשהו הינך מתבקש לא להירשם לאתר או להשתמש בשירותיו. כל האמור בתקנון
					מנוסח מטעמי נוחות בלשון זכר, אך מכוון לגברים ונשים כאחד.
				</span>
			</p>
			<p dir="rtl" style={{ textAlign: "justify" }}>
				<span style={{ fontSize: "11pt" }}>
					אנו עשויים לשנות ו/או לערוך ו/או לעדכן את תקנון האתר מעת לעת. אתה מוזמן לבדוק את העמוד מדי
					פעם כדי לוודא שאתה מכיר את כל השינויים.
				</span>
			</p>
			<h1 dir="rtl" style={{ textAlign: "justify" }}>
				<strong>
					<span style={{ fontSize: "11pt" }}>על האתר</span>
				</strong>
			</h1>
			<ol>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מפעילת האתר מספקת שירותי הכרויות אינטרנטיים שונים תחת המותג "שליש גן עדן", לרבות
							אפליקציית ואתר "שליש גן עדן" הפועל בכתובת date4dos.co.il, ואפליקציית ואתר "שליש גן עדן
							- סמארט" הפועל בכתובת "shlish.co.il" (להלן: "האתר" ו/או "האתרים").
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							ידוע לך כי השירות הינו לצורך היכרות בלבד למטרת נישואין על פי ובהתאם להלכה היהודית.
							האתר הינו אתר המיועד לקשר רציני ו/או למטרות חתונה והוא מיועד לציבור היהודי בלבד, והינו
							בעל אופי מסורתי - שמרני. מפעילת האתר עושה מאמצים על מנת לשמור על אופי זה של האתר,
							ובכלל זה, מבצעת גם חסימת משתמשים ששולחים הודעות שאינן מתאימות לרוח האתר. כמו גם, מבצעת
							מיון וסינון על בסיס הגדרה אמונית של המשתמשים.&nbsp;
						</span>
					</p>
				</li>
			</ol>
			<p dir="rtl" style={{ textAlign: "justify" }}>
				<span style={{ fontSize: "11pt" }}>
					מבלי לגרוע מהוראות תקנון זה, הנך מתבקש לכבד את צביון האתר ואת כבוד המשתמשים בו.&nbsp;
				</span>
			</p>
			<ol start={3}>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							לחברה רכיב ייחודי להיכרות בין משתמשים - "חברים משותפים", כפי שיוסבר להלן. באישור תקנון
							זה, הינך מודע לכך שהחברה עושה שימוש ברשימת אנשי הקשר של המשתמשים בשירותיה, וזאת לצורך
							מתן אפשרות השוואה בין משתמשים המעוניינים להכיר זה את זה, ומעוניינים במידע על החברים
							המשותפים (אנשי הקשר) ביניהם, כמקובל באפליקציות מוכרות בתחום זה. אפשרות זאת, עשויה לשמש
							לצורך בדיקה על המועמד לשידוך, ו/או לצורך שימוש באלגוריתם שעוזר לשדך באמצעות קשר בין
							חברים משודכים, ו/או לצורך שימוש עתידי בבינה מלאכותית וכיו"ב.&nbsp;
						</span>
					</p>
				</li>
			</ol>
			<p dir="rtl" style={{ textAlign: "justify" }}>
				<span style={{ fontSize: "11pt" }}>
					חשוב לציין כי החברה עושה ברכיב זה שימוש חדש שלא נעשה בעבר בשירותי החברה, והיא מצפינה מידע
					זה לפי מיטב הבנתה וחקירתה בתחום. אם יש לך ברשימת אנשי הקשר מידע חסוי, או שהינך חושש בשל
					כך, מומלץ לך לא להירשם לאפליקציה.
				</span>
			</p>
			<h1 dir="rtl" style={{ textAlign: "justify" }}>
				<strong>
					<span style={{ fontSize: "11pt" }}>אחריות</span>
				</strong>
			</h1>
			<ol start={4}>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							למרות הצלחות העבר של אתר "שליש גן עדן" בפרט ואתרי הכרויות בכלל, מובהר כי אין כל
							התחייבות להצלחת השירות, וכי השימוש בשירות ו/או תשלום המנוי אינו קשור להצלחה במציאת
							זיווג, אהבה, קשר או כל התקשרות כלשהי עם בני המין השני.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מפעילת האתר אינה אחראית לנזק שיגרם למשתמשי האתר בעקבות שירותי ההיכרות אותם מספק האתר.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מפעילת האתר אינה מביעה דעה ואינה תומכת בשום מידע, תוכן, שירות, מוצר או הצעה שהגישה
							אליהם ניתנת באמצעות האתר או בכל אמצעי אחר. אין לראות במפעילת האתר כמי שמעודדת או
							ממליצה להיענות להצעות או שירותים שניתנים דרך האתר. מפעילת האתר אינה בודקת את פרטי
							המשתמשים, תוכן, אמיתות וכדאיות ההצעות והמידע שמוחלף באתר.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מפעילת האתר אינה מספקת את המידע והתוכן המופיעים באתר הנוגעים לצדדים שלישיים. יודגש,
							המידע והתוכן שהגישה מתאפשרת אליהם דרך האתר הוא מידע המועלה על ידי צדדים שלישיים ולא על
							ידי מפעילת האתר.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מפעילת האתר אינה אחראית לפעולות או מחדלים של משתמשים אחרים העושים שימוש באתר. מפעילת
							האתר לא תישא על פי כל דין באחריות לכל פגיעה ו/או נזק שייגרם למשתמש באתר.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							שימוש באתר מהווה את הסכמתך לפטור את מפעילת האתר מכל אחריות בשל פעולות צד שלישי.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							בכפיפות להסתייגות של הוראות כל דין המורות אחרת, אין מפעילת האתר מביעה כל עמדה ואין היא
							אחראית, במפורש או מכללא לגבי:
						</span>
					</p>
				</li>
			</ol>
			<ol>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							התוכן, המידע והחומר המצוי באתר, לרבות בנוגע למידע ולהצעות אותם מעלים צדדים שלישיים
							שונים;
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							לגבי התוכן, החומר, המידע והפעולות אשר הגישה אליהם מתאפשרת באמצעות הגישה לאתר;
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							לגבי כל מוצר, תוכנה או שירותים, שהגישה אליהם התבצעה באמצעות קישור אלקטרוני מן האתר;
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							לגבי כל נזק אשר נגרם או עלול להיגרם עקב המשלוח של מידע אישי, חסוי או רגיש דרך האתר
							ו/או בכל אמצעי אחר;
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							לגבי כל נזק אשר נגרם או עלול להיגרם עקב פגם או תקלה בתוכנה המפעילה את האתר או שמאפשרת
							גישה לאתר.
						</span>
					</p>
				</li>
			</ol>
			<h1 dir="rtl" style={{ textAlign: "justify" }}>
				<strong>
					<span style={{ fontSize: "11pt" }}>אישור משתמשים ורישום משתמשים</span>
				</strong>
			</h1>
			<ol start={10}>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							הגלישה והשימוש באתר מותרים מגיל 18 ומעלה, בהירשמך לאתר הנך מצהיר שהנך מעל גיל 18.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מפעילת האתר משאירה לעצמה את הזכות לאשר או לא לאשר כרטיסים מסוימים מסיבות השמורות עמה,
							על פי שיקול דעתה הבלעדי.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מפעילת האתר משאירה לעצמה את הזכות לפסול לפי שיקול דעתה טקסט ו/או חלק מטקסט שהוזן ע"י
							משתמשים בכרטיסם באתר, וכן, תמונות של משתמשים מטעמי צניעות או מכל טעם אחר.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מובהר בזאת שעל המשתמש לצרף רק תמונה שלו. מפעילת האתר שומרת לעצמה את הזכות לעשות התאמות
							טכניות במידה ויהיה צורך בכך, על מנת להתאים את התמונה לאתר ולמשתמש לא תהא כל טענה בקשר
							לכך.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							פתיחת כרטיס באתר הינה לשימוש אישי בלבד של בעל הכרטיס, ולא תתאפשר כל העברה של כרטיס בין
							משתמשים, וכן לא יתאפשר שימוש של מספר משתמשים בכרטיס אחד.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מפעילת האתר רשאית לא לאשר ו/או לחסום כרטיסי משתמשים מכל סיבה שבעיניה אינם מתאימים
							לרוחו או לתדמיתו של האתר. מפעילת האתר לא תתן הסבר למשתמשים שכרטיסם או תמונתם נדחתה.
						</span>
					</p>
				</li>
			</ol>
			<h1 dir="rtl" style={{ textAlign: "justify" }}>
				<strong>
					<span style={{ fontSize: "11pt" }}>השימוש באתר</span>
				</strong>
			</h1>
			<ol start={16}>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							השימוש בשירותים המוצעים באתר ו/או חלק מהם עשוי להיות כרוך ברישום וברכישת מנוי ועל
							המשתמש לפעול בהתאם להוראות הרישום, ובכלל זה למסור פרטים נכונים ומדויקים ולעמוד בתנאי
							התשלום.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							כל הדפים המצויים באתר הם רכושה של מפעילת האתר. אין להעתיק או לפרסם כל חלק מן הדפים ללא
							הסכמתה המפורשת של מפעילת האתר בכתב ומראש.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							חל איסור חמור על כל פרסום מסחרי באתר ו/או על כל פנייה למשתמשי האתר שלא לצורך היכרות
							אישית בהתאם לתנאי ולאופי האתר ו/או כל פעילות אחרת מטעם גוף מסחרי, אשר אינה לצורכי
							היכרות אישית בלבד, ובכלל זה פרסום אתרים אחרים באמצעות האתר, במישרין ו/או בעקיפין ו/או
							פרסום קישוריות (לינקים) שיובילו לאתרים אחרים ושימוש בשירותי ההודעות של האתר לכל אחת
							מהמטרות האסורות המפורטות לעיל.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							האתר שומר לעצמו את הזכות לשנות את מבנה האתר, תוכן האתר, שאלון ההרשמה. כמו כן, תוכל
							מפעילת האתר לשנות כל דבר שקשור בקוד האתר או בהצגתו למשתמש. אין האתר אחראי לטעויות
							בהצגת הנתונים במידה ויווצרו בעקבות כך.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							משתמש הנרשם לשירות, מודע לכך ונותן את הסכמתו המפורשת לכך שהאתר מתכוון לפעול בפלטפורמות
							שונות שקיימות ו/או שיהיו קיימות בעתיד ואף תחת מותגים שונים, וכי כרטיסו עתיד להיות מוצג
							בכל פלטפורמה כזו לכשתצא.&nbsp;
						</span>
					</p>
				</li>
			</ol>
			<p dir="rtl" style={{ textAlign: "justify" }}>
				<span style={{ fontSize: "11pt" }}>
					כמו כן, האתר, בין בגרסתו למחשב, ובין בגרסתו הסלולארית מותאם למכשירים תומכים בלבד, ואינו
					מתאים לכל סוגי המכשירים הקיימים בשוק. מפעילת האתר שומרת לעצמה את הזכות לשווק את האתר בכל
					אמצעי אלקטרוני שתחפוץ בו.
				</span>
			</p>
			<ol start={21}>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מפעילת האתר משתמשת בפונקציה בה מופיעות תמונות של גולשים בעמוד הבית של האתר (ניתן לבקש
							שלא להופיע בעמוד הראשי). כמו כן, משתמשים מסוימים יופיעו בתוצאות החיפוש גם בכניסה לאתר
							ע"י משתמשים שאינם רשומים עדיין לאתר ומבצעים חיפוש. מפעילת האתר לא תהיה אחראית לנזק
							פגיעה עלבון או הפסד כלשהו שייגרם למשתמש קיים עקב שימוש בפונקציות אלה.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מפעילת האתר בוחרת &nbsp;את סדר ותדירות הופעת המשתמשים בתוצאות החיפוש ואת המשתמשים
							שיופיעו לכל משתמש ו/או קבוצה של משתמשים בהתאם לשיקול דעתה כמו כן מפעילת האתר בוחרת
							להציג משתמשים מסוימים בעמוד הבית בהתאם לשיקול דעתה.&nbsp;
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מפעילת האתר אינה אחראית לכל נזק שעשוי להיגרם מהיעדר זמינותו התמידית של האתר, כמו כן,
							לא לתקלות טכניות שיהיו בו או למידע שיישמר באתר, וכן, לנזק שיכול להיגרם מהפסקת הפעלת
							האתר.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מפעילת האתר רשאית לבצע למשתמשים סינון ראשוני של ההודעות ו/או משתמשים ו/או כל תוכן אחר
							והכל על פי הגדרות ברירת מחדל או אלגוריתם הנתון לשיקול דעתה. הודעות מסוננות ניתן יהיה
							לקרוא בתיבה מיוחדת שנקראת "דואר מסוננים", שם גם כל משתמש יוכל להגדיר את הסינון הרצוי
							לו בצורה אחרת.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מפעילת האתר רשאית להפעיל נוסחאות שונות לתצוגה והתאמה בין משתמשים, וייתכן ולא תראה את
							כלל המשתמשים שנרשמו לאתר, כך למשל נוסחאות לחסימת תצוגת משתמשים בני אותו מין, כהנים
							וגרושות, משתמשים עם פער גילאים בלתי סביר, ועוד, הכל על פי שיקול דעתה הבלעדי של החברה.
							בהירשמך לאתר, הינך מצהיר שאתה מסכים לעובדה זאת.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מבלי לגרוע מהאמור לעיל, בכניסתך לאתר אתה מתחייב במפורש שלא:&nbsp;
						</span>
					</p>
				</li>
			</ol>
			<ol>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							לעשות כל שימוש במידע על משתמשי האתר ו/או בתוכן מכל סוג שהוא, לרבות, אך מבלי להגביל,
							בתמונות, בסרטונים ובקטעי אודיו, שאין לך את הזכות ו/או הרשות לעשות בו שימוש.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							למסור פרטיו של אחר, זולת המשתמש, למעט, בכפוף לאישורו המפורש של אותו משתמש.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							להטריד, לאיים, "לעקוב", לפגוע, להעליב, ולהפר כל זכות משפטית שעומדת לאדם או לגוף אחר.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							להעלות, לפרסם, להגיב, לכתוב, לאסוף, כל תוכן בעל אופי שאינו הגון, ראוי, פורנוגראפי,
							משמיץ או שאינו חוקי.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							להעלות, לפרסם, להגיב, לכתוב, לאסוף, כל תוכן שמטרתו היא מסחרית, שמטרתו פרסום, שידול
							לרכישה, מתן שירותים, תרומות או שמטרתו להוליך שולל משתמש, לרבות להעלות, לפרסם, להגיב,
							לכתוב, לאסוף, כל תוכן מסחרי ללא קבלת היתר מפורש, בכתב ומראש ממפעילת האתר.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							להעלות, לפרסם, להגיב, לכתוב, לאסוף כל חומר שעלול להוות עבירה פלילית, עלול ליצור עילה
							אזרחית, שמפר דין ישראלי או דין של מדינה אחרת.&nbsp;
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							לאסוף, "לקצור" מידע לגבי משתמשים אחרים, לרבות, ולא רק, איסוף כתובות דואר אלקטרוני,
							מידע אישי, תמונות או חומרים אחרים ברשתות חברתיות של משתמשים אחרים.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							להעלות, לפרסם, להגיב, לכתוב, לאסוף, כל תוכן שיש בו כדי לפגוע בביטחונה של מדינת ישראל
							ו/או שיש בו כדי להפר סוד צבאי ו/או צנזורה צבאית.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							להפעיל או לאפשר להפעיל כל יישום מחשב או כל אמצעי אחר, לרבות תוכנות מסוג Crawlers,
							Robots וכדומה, לשם חיפוש, סריקה, העתקה או אחזור אוטומטי של תכנים מתוך האתר. בכלל זה,
							אין ליצור ואין להשתמש באמצעים כאמור לשם יצירת לקט, אוסף או מאגר שיכילו תכנים מהאתר.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							להציג תכנים מהאתר בכל דרך שהיא ובכלל זה באמצעות כל תוכנה, מכשיר, אביזר או פרוטוקול
							תקשורת - המשנים את עיצובם באתר או מחסירים מהם תכנים כלשהם ובפרט פרסומות ותכנים
							מסחריים.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							לבצע שינויים באתר או להעתיק, להפיץ, לשדר, להציג, לבצע, לשכפל, לפרסם, לתת היתר או
							רישיון, ליצור עבודות נגזרות או למכור פריט מפריטי המידע, התוכנה, המוצרים או השירותים
							שמקורם באתר.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>לשתול קודים ו/או וירוסים ו/או תוכנות באתר.</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							לשלוח דואר זבל לחברי האתר או להתחיל מכתבי שרשרת כלשהם.
						</span>
					</p>
				</li>
			</ol>
			<h1 dir="rtl" style={{ textAlign: "justify" }}>
				<strong>
					<span style={{ fontSize: "11pt" }}>פרסום, המלצות מערכת וקבלת דוא"ל</span>
				</strong>
			</h1>
			<ol start={27}>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							בהירשמך לשירות הינך מסכים לכך שמפעילת האתר רשאית, על פי שיקול דעתה, לאפשר למשתמשים,
							ואף לגביך, לקבל, לשלוח, להפיץ מודעות שאינן מודעות אישיות (לרבות פרסומות), על פי פילוח
							אשר מותאם לאותם משתמשים. לשם המחשה, יובהר כי למשתמשים שאינם מנויים, מודעה זו כאמור
							תוצג כ"מסר כללי" (בצירוף שם השולח) להבדיל מ"מסר אישי". בנוסף, בהירשמך לשירות הנך מסכים
							שמפעילת האתר תמליץ ו/או תשתף את כרטיסך עם משתמשים אחרים הרשומים לשירות ו/או עם משתמשים
							נוספים שמחפשים זוגיות ומצויים בפלטפורמות אחרות, ולהיפך, כל זאת על פי שיקול דעתה
							הבלעדי, שירות זה עשוי להופיע &nbsp;למשתמש כ"המלצת מערכת" או בכותרת מתאימה אחרת או
							בסימון מבדיל אחר, בהתאם להקשר הקידום הרלוונטי שמבוצע על כרטיס המשתמש. משתמש אשר אינו
							מעוניין בקבלת המלצות מערכת או מסרים כלליים או להיות מופץ בפלטפורמות אחרות, באפשרותו
							לפנות לשירות הלקוחות בדרכים המקובלות ולבקש שלא לקבל תפוצה או פניות מעין אלו.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							דרכי יצירת קשר, לרבות מספרי טלפון וכתובות הדוא"ל שימסרו למפעילת האתר ע"י משתמשים או
							ע"י אורחים באתר, אשר תכליתם קבלת מידע או חומר פרסומי מחברות שונות, עשויים להימסר גם
							לחברות השונות לצורך פרסום. אין החברה אחראית על אופי השימוש של החברות בדרכי יצירת הקשר
							הנמסרים, ואין מפעילת האתר אחראית לנזק ישיר או עקיף, פיזי , נפשי, כספי או כל נזק שהוא
							שייגרם למשתמש או אורח שהשאיר את דרכי יצירת הקשר שלו באתר.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מפעילת האתר תהא רשאית לצרף את המידע שקיבלה על המשתמש למאגר מידע שקיים אצלה, יודגש, כי
							על פי חוק לא חלה עליך חובה למסור את המידע.&nbsp;
						</span>
					</p>
				</li>
			</ol>
			<p dir="rtl" style={{ textAlign: "justify" }}>
				<span style={{ fontSize: "11pt" }}>
					כמו כן, בברירת המחדל בהרשמה לאתר, יסומן שהחברה רשאית לשלוח למשתמש, ללא הגבלה, דיוור ו/או
					הודעות sms ו/או מיילים, ו/או מסרונים בכל דרך אחרת כגון הודעות "וואטסאפ" על הודעות חדשות
					ממשתמשי האתר וכן על הודעות שיכללו מידע פרסומי ועוד, לרבות הודעות מטעמם של צדדים שלישיים.
					על מנת לשנות הגדרות אלו על המשתמש לשנות את "הגדרות הדואר" כפי שהם מופיעות בפרופיל האישי,
					בהגדרות המערכת של המשתמש.&nbsp;
				</span>
			</p>
			<ol start={30}>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							אישור תקנון זה ומסירת פרטי המשתמש מהווה הסכמה לשיגור דברי פרסומת על פי חוק התקשורת
							(בזק ושידורים) (תיקון מס' 40), התשס"ח – 2008 (להלן:"החוק").
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							אף על פי כן, המשתמש רשאי לבטל את הסכמתו על ידי משלוח הודעה להנהלת האתר בכתובת
							&nbsp;info@shlish.co.il , וכן בשאר הדרכים המצויינות.&nbsp;
						</span>
					</p>
				</li>
			</ol>
			<p dir="rtl" style={{ textAlign: "justify" }}>
				<span style={{ fontSize: "11pt" }}>
					כל עוד לא נמסרה הודעת ביטול להסכמה לקבלת דברי פרסומת, מפעילת האתר תהא רשאית, בהתאם להוראות
					החוק, להשתמש בפרטי המשתמש, כפי שנרשמו באתר, ו/או להעביר פרטים של משתמשים לצדדים שלישיים על
					מנת לחשוף את המשתמשים הרשומים לעדכונים ו/או חומר שיווקי רלבנטי בכל אמצעי מדיה.
				</span>
			</p>
			<ol start={32}>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מפעילת האתר תהיה בקשר עם מפרסמים שונים, אין למפעילת האתר כל קשר לנזק כספי, גופני או
							נפשי שיכול להיגרם למשתמש באתר כתוצאה מהשימוש באחד מהמוצרים של המפרסמים באתר, או
							בפרסומות המפורסמות באמצעות האתר ובאמצעות מערכת הדיוור של האתר.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							כל שימוש של הגולש במספר הטלפון שלו באתר, לרבות בעת: רכישת מנוי, פנייה לשירות הלקוחות,
							הזנת פרטים באתר, הזנת פרטים לחזרה בעקבות מבצעים ו/או מודעות פרסומיות אחרות, וכדומה,
							יישמרו במאגר הנתונים של החברה ויוכלו לשמש את החברה לכל צורך עתידי, לרבות בעת
							משלוח/פנייה פרסומית.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מפעם לפעם יעלו פרסומים שיווקיים באתר, המוצעים לחברים בו. יובהר בזאת כי הנתונים, פרטי
							התקשרות, החבילה וטיב השירות אינם באחריות שליש גן עדן, כאשר אינה מהווה צד להתקשרות ו/או
							לפרסום, אלא מול החברה המשווקת ובהסכמתך למבצע הנך מאשר בזאת כי אין ולא תהיה לך כל טענה
							כנגד שליש גן עדן בגין ההתקשרות בינך לבין החברה המשווקת. כל המבצעים המוצעים מוגבלים
							בזמן, החברה רשאית לשנות/לקצר/להאריך את המבצע ככל שתרצה.&nbsp;
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מנהלת האתר אינה אחראית לתוכן של הפרסומות האמורות ולמוצרים שיסופקו או לשירותים שיינתנו
							על-ידי צדדים שלישיים והמשתמש מסכים באופן בלתי חוזר וללא כל תנאי לא לראות במנהלת האתר
							אחראית, בכל דרך, בקשר לכך.
						</span>
					</p>
				</li>
			</ol>
			<h1 dir="rtl" style={{ textAlign: "justify" }}>
				<strong>
					<span style={{ fontSize: "11pt" }}>התוכן והמידע המוזן לאתר</span>
				</strong>
			</h1>
			<ol start={36}>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							המונחים "מידע" ו/או "תוכן", כוללים מידע מכל מין וסוג, לרבות אך מבלי למצות, כל תוכן
							מילולי, חזותי, קולי, אור-קולי &nbsp;(audio-visual) או כל שילוב שלהם, וכן עיצובם,
							עיבודם, עריכתם, הפצתם ודרך הצגתם, לרבות אך מבלי למצות, כל תמונה, צילום, איור, הנפשה
							(animation), תרשים, דמות, הדמיה, דגימה (sample), סרטון, קובץ קולי וקובץ מוסיקלי, כל
							תוכנה, קובץ, קוד מחשב, יישום, תסדיר (format), פרוטוקול, מאגר נתונים, ממשק וכל תו,
							סימן, סמל וצלמית (icon). כל אלה יכונו להלן: "
						</span>
						<strong>
							<span style={{ fontSize: "11pt" }}>תוכן</span>
						</strong>
						<span style={{ fontSize: "11pt" }}>".</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							העלאת כל תוכן לאתר, מהווה הסכמה מפורשת למתן רשות לידי מפעילת האתר לעשות כל שימוש בתוכן
							זה.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							הינך מתחייב שלא להעלות, לשלוף, לשדר, להפיץ או לפרסם כל תוכן, מידע או חומר אחר לרבות אך
							מבלי למצות סרטונים, סרטים, הודעות, תמונות, קבצים מסוגים שונים, קישורים, טקסטים וכל
							מידע אחר, אשר:
						</span>
					</p>
				</li>
			</ol>
			<ol>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							עלולים להפר זכויות קניין רוחני של הנהלת האתר או של כל אדם אחר, לרבות, זכויות יוצרים,
							פטנטים, סימני מסחר, סודות מסחריים או כל זכות קניינית אחרת;
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							עלולים להגביל או למנוע מאחרים את השימוש באתר שלא בהתאם ליכולות האתר;
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							אסורים לפרסום או לשימוש, והם בגדר לשון הרע, גזענות, איום, פגיעה, העלבה, הוצאת דיבה,
							השמצה, פורנוגרפיה, ביטוי וולגרי אחר, תכנים בעלי אופי מיני בוטה;
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							הם בגדר דברים העלולים לעודד, לשדל, להמריץ או לסייע לאחר לבצע מעשה אסור על-פי חוק או
							עלולים לעורר אחריות משפטית, תכנים שיש בפרסומם עבירה פלילית או אזרחית, תכנים הפוגעים
							בפרטיות, תכנים מזיקים, תכנים שפוגעים ברגשות הציבור;
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							הם בגדר שליחת הודעות ספאם (Spam), הודעות שרשרת, הודעות אחידות, העלאה של קוד תוכנה
							לאתר, העברת מכתבי שרשרת או דואר זבל, מכל סוג, פרטי או מסחרי;
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							כוללים תוכנת מחשב, קוד מחשב או יישום הכוללים נגיף-מחשב ("וירוס"), סוסים טרויאניים,
							תולעים (Worms), ואנדלים (Vandals), יישומים מזיקים (Malicious Applications), פצצות זמן,
							רכיבי Cancelbot, קבצים פגומים או תוכנה אחרת או תוכניות דומות אחרות העלולות לפגוע
							בפעילות תקינה של האתר או של מחשב של אדם אחר או ברכושו של אחר;
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							כוללים תוכן המנוגד לכללי השימוש המקובלים באינטרנט או העלול לגרום נזק או לפגוע במשתמשי
							אינטרנט בכלל, ובמשתמשי האתר בפרט;
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							כוללים תוכן העלול להטעות משתמש לרבות כל פרט אישי שלך או של כל משתמש אחר באתר שאיננו
							נכון ומדויק;
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							כוללים תוכן הנוגע לקטינים ומזהה אותם, את פרטיהם האישיים או את מענם ודרכי ההתקשרות
							עימם;
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>כוללים תוכן הידוע לך שהוא שקרי, מטעה או מסולף.</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>כל תוכן שההנהלה תמצא לנכון להסיר.</span>
					</p>
				</li>
			</ol>
			<ol start={39}>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מפעילת האתר אינה מנטרת ואינו בודקת את כלל המידע המועלה לאתר ואין היא יכולה לבדוק ולנטר
							את כלל מידע המועלה לאתר, לרבות נכונותן של פרטי המשתמשים, הצעות, לרבות &nbsp;את השאלה
							האם יש בתוכן המועלה לאתר כדי לפגוע בצדדים שלישיים.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מפעילת האתר רשאית להסיר כל תוכן שיש בו פגיעה בזכויות של צדדים שלישיים, בין באמצעות
							הפרה של זכות יוצרים, זכויות קניין רוחני בסימני מסחר, סודות מסחריים, הוצאת לשון הרע,
							פגיעה בפרטיות, פרסום תוכן פורנוגראפי ובלתי הולם בפני קטינים, פגיעה בשם הטוב ובמוניטין
							של צדדים שלישיים, פגיעה בביטחון המדינה וכל תוכן אחר שההנהלה תמצא לנכון להסיר או לחסום
							על פי שיקול דעתה הבלעדי.&nbsp;
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							אתה מודע לכך שבמהלך השימוש באתר ייתכן ותיחשף לתכנים ממקורות שונים, שמפעילת האתר אינה
							אחראית להם בשום צורה.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							אין לראות בשום מידע ו/או תוכן ו/או המלצה ו/או הדרכה באתר כהתחייבות ו/או אבטחה של
							מפעילת האתר למימושם. האחריות לתכנים המפורסמים על ידי צדדים שלישיים באתר תהא על המשתמש
							בלבד, ומפעילת האתר לא תישא באחריות כלשהי בגינן, כאמור.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מפעילת האתר שומרת את הזכות בכל עת למסור לאחרים כל מידע אשר יידרש ממנה לפי חוק, כמו גם
							על זכותה לערוך, להסיר ולסרב להעלות אל האתר כל מידע, תוכן והודעה, שלפי שיקול דעתה
							הבלעדי של ההנהלה פוגע או מפר תנאי מתנאי הסכם זה.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מפעילת האתר אינה נותנת חסות או מביעה כל דעה לגבי נכונותם או דיוקם של התכנים, הצהרות,
							הבעות עמדה, עצות או כל מידע אחר המוצג באתר ע"י המשתמשים. אתה מודע לכך שכל הסתמכות שלך
							על התכנים, הצהרות, הבעות עמדה, עצות או כל מידע אחר המוצג באתר נעשה לפי שיקול דעתך
							ובאחריותך הבלעדית.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							העלאת תכנים לאתר כפופה להצהרת הגורם אשר מעלה תוכן לאתר כי התוכן אינו מפר זכויות קניין
							רוחני, אינו מהווה לשון הרע, אינו פוגעני וכיו"ב.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<h6 dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מפעילת האתר אינה חברת איחסון או חברת שליחת הודעות, ומיותר לציין, שלפיכך, ייתכן שחלק
							מתכני האתר ימחקו אוטומטית כגון "הקלטות קוליות", וכן תכתובות והודעות, לרבות כאלה, אשר
							עבר זמן רב ממועד כתיבתם.
						</span>
					</h6>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							המשתמש מתחייב כי יעלה לאתר אך ורק תכנים ו/או תצלומים ו/או קטעי וידאו הרלוונטיים לצורך
							מטרות האתר – היכרות אישית.
						</span>
					</p>
				</li>
			</ol>
			<h1 dir="rtl" style={{ textAlign: "justify" }}>
				<strong>
					<span style={{ fontSize: "11pt" }}>נוהל הודעה והסרה</span>
				</strong>
			</h1>
			<ol start={47}>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מפעילת האתר מקפידה על קיום הוראות החוק ומכבדת את זכותם של משתמשי האתר וצדדים שלישיים.
							אם בכל זאת נמצא כי האתר מכיל תוכן המפר זכויות קניין רוחני, מהווה לשון הרע, פוגעני
							וכיו"ב, הנהלת האתר מתחייבת לפעול להסרת התוכן הרלוונטי לאחר מסירת הודעה כאמור, בהתאם
							ל"נוהל הודעה והסרה", על פי דין.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							על כן, אם אתה סבור כי פורסם באתר תוכן הפוגע בך או בצד ג', אנא פנה אלינו בהקדם האפשרי
							ואנו נשתדל לטפל בפנייתך בכל ההקדם. בפנייתך עליך לפרט את טענותיך, לצרף הפנייה אל החומר
							הפוגע, לצרף כל מסמך אשר עשוי להעיד על זכויותיך ועל עצם הפגיעה בהן. פניות כאמור ניתן
							להעביר לכתובת הבאה:info@shlish.co.il .
						</span>
					</p>
				</li>
			</ol>
			<h1 dir="rtl" style={{ textAlign: "justify" }}>
				<strong>
					<span style={{ fontSize: "11pt" }}>סודיות, זהירות ודיווח לרשויות על עבירה</span>
				</strong>
			</h1>
			<ol start={49}>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מפעילת האתר משתמשת בשיטות אבטחה הידועות לה ולחברה המקימה את האתר, על פי דרישות החוק
							וחברות כרטיסי האשראי, מפעילת האתר לא תישא באחריות למקרים של נזק שייגרם למשתמש בעקבות
							מידע שהשאיר באתר. ותעשה ככל יכולתה במידות ההיגיון הסבירות הטכנולוגיה הנפוצה הנהוגה
							ברוב אתרי המסחר וההיכרויות על מנת למנוע מקרים אלו.&nbsp;
						</span>
					</p>
				</li>
			</ol>
			<p dir="rtl" style={{ textAlign: "justify" }}>
				<span style={{ fontSize: "11pt" }}>
					פעילות באתר דורשת חשיפת פרטים מעבר למקובל בתחום הדייטינג וזאת בין היתר גם בשל אופי האתר
					והקהילה. החברה עושה מאמצים סבירים ומקובלים על מנת לאבטח את המידע היושב בשרתים וכן לעשות
					שימוש באחסון עיבוד ושליפת המידע באמצעות חברות צד ג' מוכרות וידועות ובהתאם למיטב הבנתה
					ושיקול דעתה, אולם אם הנך רגיש לפרטיות שלך אל לך להירשם לאפליקציה, ובכל מקרה אל לך לרשום או
					&nbsp;למסור שום תוכן רגיש.
				</span>
			</p>
			<ol start={50}>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מפעילת האתר תשמור בסודיות את המידע שיירשם בכרטיס המשתמש באתר. אין מפעילת האתר תהיה
							אחראית על מידע שנפרץ, או נגנב מן המערכת או מהתרשלות של החברה המאחסנת את האתר. כמו כן,
							מפעילת האתר לא תהא אחראית לשום נזק שיגרם בשל בעיות בתוכנה או בקוד האתר שלא נעשו במטרת
							זדון. ידוע למשתמש, שאתרי הכרויות רבים בארץ ובעולם נפרצו ולכן אין לכתוב מידע רגיש ואישי
							מדי בצ'אט בין המשתמשים השונים.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מפעילת האתר חוזרת ומבקשת לא להעביר למשתמשי האתר מידע העלול לגרום לפגיעה פיזית ו/או
							נפשית ו/או כספית או כל פגיעה אחרת בהם. אין להעביר למשתמשים מספר כרטיסי אשראי, יש
							מתחזים רבים באפליקציות ובאתרי הכרויות בייחוד ממדינות זרות נא להיזהר.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מפעילת האתר תבדוק כרטיסים מדגמית וזאת על פי שיקול דעתה ולמטרת שמירה על הצביון המיוחד
							של האפליקצייה וכן במקרה של דיווח ו/או חשד לעבירה פלילית ו/או הונאה ו/או הטרדה כל תוכן
							שנכתב ע"י גולשי האתר על מנת להגן על פעילותה ולשמור את האתר מנזקים שאותם יגרמו המשתמשים
							שייבדקו. ובמידת הצורך, החברה אף שומרת על זכותה להעביר את המידע לרשויות האכיפה על פי
							שיקול דעתה.
						</span>
					</p>
				</li>
			</ol>
			<h1 dir="rtl" style={{ textAlign: "justify" }}>
				<strong>
					<span style={{ fontSize: "11pt" }}>חסימת משתמשים ומחיקת משתמשים</span>
				</strong>
			</h1>
			<ol start={53}>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מפעילת האתר שוקדת לשמור על רמה נאותה ובטוחה של תפעול האתר והשירותים הניתנים בו. אנא
							דווח על כל בעיה ו/או תוכן פוגעני ו/או כל הפרת מדיניות.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							אתה רשאי להשתמש בשירותים שלנו אך ורק כפי המותר בתנאי הסכם זה ועל-פי כל חוק הניתן
							להחלה.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מפעילת האתר רשאית בכל עת, ומבלי לספק אזהרה או הודעה מוקדמת, להשעות את חשבונך או לסגור
							את חשבונך, ו/או להקטין או לאפס את החשיפות שאתה מקבל, בשל פעילות לא חוקית ו/או אי עמידה
							בתנאי הסכם זה ו/או חוסר התאמה לרוח הקהילה של האתר.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							במידה שתפר את תנאי השימוש ו/או במידה שמפעילת האתר תשעה או תסגור את חשבונך, לא תורשה
							ליצור חשבון חדש.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							אם מפעילת האתר תסגור את חשבונך עקב פעילות לא חוקית או אי עמידה בתנאי הסכם זה, לא תהיה
							זכאי לכל החזר דמי מנוי.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מפעילת האתר שומרת לעצמה את האפשרות למחוק ו/או להקפיא ו/או לחסום כרטיסים ו/או מידע מתוך
							כרטיסים מסוימים בהתאם לשיקול דעתה, ולרבות, אך מבלי למצות, במצבים הבאים:
						</span>
					</p>
				</li>
			</ol>
			<ol>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>משתמשים שלא נכנסו זמן רב לאתר;</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>משתמשים שלא מתאימים לרוח האתר;</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							משתמשים שיש בנוכחותם בכדי פגיעה באתר ו/או פגיעה בגולשי האתר;
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>משתמשים שהואשמו בפלילים.</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							משתמשים אשר ירשמו בכרטיס שלהם בפירוט ה"על עצמי" או בכל מקום אחר, פרטים מזהים שאיתם
							אפשר ליצור קשר מחוץ לאתר כגון אי-מייל, טלפון, שם מלא, שם בפייסבוק או בתוכנות מסרים
							כלשהן וכדומה.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							פעולת המחיקה ו/או ההקפאה עשויה לכלול נתוני כרטיסים ו/או מידע בקשר לכרטיסים האמורים,
							ו/או חסימת ו/או מחיקת מידע בקשר להודעות, צפיות, חיוכים וכד'.
						</span>
					</p>
				</li>
			</ol>
			<ol start={59}>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							אם מפעילת האתר תסגור או תשהה או תגביל או תמחק או תחסום או תקפיא את חשבונך ואתה חולק על
							כך, עליך להודיע למפעילת האתר בתוך 14 ימים ממועד סגירת חשבונך כאמור על ידי הגשת הודעת
							ערעור. אם לא תמסור הודעה כאמור, נראה אותך כמי שוויתר על כל טענה בנוגע לשלילת הזכות
							להחזר דמי המנוי או לסגירת חשבונך.
						</span>
					</p>
				</li>
			</ol>
			<h1 dir="rtl" style={{ textAlign: "justify" }}>
				<strong>
					<span style={{ fontSize: "11pt" }}>רכישת מנוי ומדיניות ביטולים</span>
				</strong>
			</h1>
			<ol start={60}>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							רכישת מנוי באתר, מקנה זכות גישה מיידית לכל ההודעות שנשלחו אל המשתמש משחר ההיסטוריה,
							וכן זכות גישה לכל ההודעות שהמשתמש שלח למשתמשים אחרים, גם בתקופה בה המשתמש לא היה מנוי
							- כל עוד המנוי בתוקף.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							רכישת המנוי כוללת עסקאות של מסלולים לתקופות שונות ומחירים שונים, בהתאם למחירון החברה,
							כפי שנקבע על ידי מפעילת האתר מעת לעת, לרבות מנוי לתקופה בלתי מוגבלת בזמן.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>כל רכישת מנוי מהווה בכל מקרה רכישה בעסקה אחת.</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							רכישת מנוי באתר מאפשרת למנויים אופציות שאינן ניתנות למשתמשים שאינם מנויים, מפעילת האתר
							שומרת לעצמה את הזכות לשנות את האופציה, לגרוע וכמובן להוסיף מהאפשרויות הפתוחות בפני
							בעלי המנוי וזאת בהתאם לשיקול דעתה הבלעדי, ומבלי הודעה מוקדמת למנויים.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							החל ממועד תשלום דמי המנוי ועד למועד פקיעת המנוי, נפתחות בפני המנוי אפשרויות וזכויות
							&nbsp;השמורות למנויים בלבד, וזאת ללא תשלום נוסף. מפעילת האתר שומרת לעצמה את הזכות
							לשנות את מחיר חידוש המנוי בכל עת, ללא התראה או הודעה מוקדמת. וכן להוסיף ו/או לתמחר
							אפשרויות נוספות במחיר נוסף על גבי מחיר המנוי והכל כמקובל באפליקציות דייטינג מוכרות
							בעולם הפועלות בישראל.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							יובהר כי התשלום הינו בעבור זכות הגישה וזכות השימוש ולא בעבור שימוש בפועל, ובהתאם, העדר
							שימוש ו/או ניצול המנוי אינו מהווה עילה להחזר כספי מכל סיבה.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							כל רכישת מנוי תחויב בכל מקרה, גם במקרה של ביטול העסקה, בעלות מחיר המחירון לתקופה
							היחסית בה ניתנה למשתמש זכות הגישה.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מחיר המחירון למימוש זכות שימוש/גישה חד-פעמית לכלל ההודעות, אפשרות המוצעת גם במסלול
							"מנוי ליום", &nbsp;הינו 50 ₪.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מחיר המחירון החודשי באתר, עומד כיום על 129 ש"ח לחודש, ומעודכן מעת לעת, על פי שיקול
							דעתה של החברה.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מפעילת האתר רשאית להעניק הנחה ממחיר המחירון, בין היתר, במסלולים עם התחייבות לתקופה
							קצובה.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							שים לב כי מדיניות ביטול רכישת המנוי באמצעות האפליקציה של 'שליש גן עדן' למשתמשי הסלולר
							הגולשים בה באמצעות מכשירי חברת אפל (Apple) שונה וכפופה לפרטי ההסכם בין המשתמש ובין
							חברת אפל אליה הוא מנוי ודרכה ניתן השירות, ובה מדיניות ביטולים שונה מזו המפורטת להלן.
							כמו כן, במכשירי אנדרואיד תיתכן אפשרות לרכישת מנוי באמצעות שירות התשלומים שמציעה חברת
							גוגל, גם במקרה זה, מדיניות ביטול רכישת המנוי שונה וכפופה לפרטי ההסכם בין המשתמש ובין
							חברת גוגל אליה הוא מנוי ודרכה ניתן השירות, ובה מדיניות ביטולים שונה מזו המפורטת
							להלן.&nbsp;
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							כמו כן מובהר בזאת כי הקפאת ו/או חסימת ו/או מחיקת החברות באתר לא מחייבים הפסקת ספירת
							הימים הנותרים למנוי ע"י המערכת. ללא קשר לאמור לעיל החברה תהיה רשאית להקפיא מנוי לזמן
							קצוב על דעתה בלבד.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							בתשלום באמצעות כרטיס אשראי, יש להזין את מספר הכרטיס ופרטיו בעת ביצוע ההזמנה באתר.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							לא ניתן לבטל או להעביר מנוי לאחר הזמנה ותשלום באתר, אלא בהתאם להוראות חוק הגנת הצרכן,
							תשמ"א - 1981.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							בעסקאות תשלומים. כרטיס אשראי שאינו תומך באפשרות זאת יחויב בתשלום אחד.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מפעילת האתר רשאית להעניק מנויים לאנשים או לבטל את הענקתם ללא הודעה מוקדמת וללא תנאי.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							ברכישת מנוי מתמשך, נותן הרוכש הסכמתו לתשלום דמי המנוי החודשיים לתקופה בלתי קצובה.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							המנוי רשאי להודיע בכל עת בה יחפוץ על ביטול המנוי ללא קנס ו/או דמי ביטול, בכפוף לחיוב
							החלק היחסי בגין התקופה בה ניתנה זכות הגישה כמנוי לאתר, על פי המחירון היומי ו/או
							החודשי, בהתאם לעניין ולמשך הזמן שחלף עד למועד ביטול המנוי.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							למען הסר ספק, במקרה של ביטול ההתקשרות, במהלך תקופת התחייבות מינימאלית, תבוטל ההנחה
							ממחיר המחירון והמנוי יראה, לצורך קבלת זיכוי כספי, כמי שרכש מנוי על פי מחיר המחירון
							לתקופה הרלוונטית, ובהתאם, ההתחשבנות לצורך ביטול העסקה תתבצע על בסיס מחיר המחירון,
							כדלקמן:
						</span>
					</p>
				</li>
			</ol>
			<ol>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							בוטל המנוי במהלך תקופת החודש הראשון להתקשרות - יחויב המנוי לפי מחיר המחירון של "מנוי
							ליום" בגין היום הראשון של המנוי, ובגין כל יום נוסף עד למועד ביטול המנוי, על פי החלק
							היחסי ממחיר המחירון החודשי של החברה, אך בכל מקרה החיוב לא יעלה על מחיר המחירון של מנוי
							חודשי באתר.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							בוטל המנוי לאחר חודש ימים- יחויב המנוי לפי מחיר המחירון החודשי, הן בגין כל חודש מלא
							שחלף ממועד יום רכישת המנוי ועד למועד ביטול המנוי, והן בגין החלק היחסי של הימים אשר
							נוצלו במהלך החודש בו בוטל המנוי (חודש אשר לא נוצל במלואו).
						</span>
					</p>
				</li>
			</ol>
			<ol start={79}>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							המנוי רשאי להודיע בכל עת בה יחפוץ על ביטול המנוי לאתר, באמצעות פניה למוקד שירות
							הלקוחות של המפעילה בטלפון 08-6519660 או בפקס 08-6217553 או באמצעות משלוח דואר אלקטרוני
							לכתובת info@shlish.co.il או באמצעות פניה בדואר אל המפעילה (חברת שליש גן עדן בע"מ)
							לכתובת: גולדה מאיר 3, נס ציונה. (להלן: "הודעת הביטול"). המפעילה תחדל לחייב את המנוי
							בדמי המנוי החודשיים לאתר, בתוך שלושה ימי עסקים מיום מסירת הודעת הביטול, ובמידה והודעת
							הביטול נמסרה בדואר רשום - בתוך שישה ימי עסקים מיום מסירת הודעת הביטול למשלוח
							("הביטול").
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							יודגש כי במקרה של ביטול מנוי לאחר תקופת ההתחייבות המינימאלית הקבועה במסלול, ככל שתהיה
							במסגרת תקופה קצובה לפי הגדרות שלעיל, המפעילה לא תגבה סכום כלשהו כדמי ביטול. במקרה
							כאמור, אם בוטל המנוי הרלוונטי במהלך החודש שעבורו שולמו דמי המנוי, יינתן למנוי החזר
							יחסי בלבד של דמי המנוי, בהתאם למחיר הרלוונטי אשר שולם על ידי המשתמש עד למועד ביטול
							המנוי, וזאת בתוך 14 ימים מיום מסירת הודעת הביטול. לא יינתן החזר כספי בגין התקופה בה
							נעשה שימוש בשירותים המוצעים באתר בתשלום ואשר קדמה למועד הביטול.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מנוי רשאי להודיע בכל עת בה יחפוץ על רצונו להפסיק את החיוב החודשי על המנוי, כך שהוא
							יוכל להמשיך ולעשות שימוש בשירותים עד תום אותו חודש קלנדרי ומהחודש העוקב לא יחויב בגין
							המנוי, וזאת באמצעות פנייה לעמוד "הגדרות החשבון שלי" או באמצעות פניה למוקד שירות
							הלקוחות בדרכים המצוינות לעיל, ואולם כאמור במקרה של ביטול ההתקשרות במהלך תקופת
							ההתחייבות המינימאלית תהיה בעלת האתר רשאית לגבות את מלוא סכום ההתחייבות.
						</span>
					</p>
				</li>
			</ol>
			<h1 dir="rtl" style={{ textAlign: "justify" }}>
				<strong>
					<span style={{ fontSize: "11pt" }}>אפליקציה סלולארית במכשיר אייפון או אנדרואיד</span>
				</strong>
			</h1>
			<ol start={82}>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מפעילת האתר מפעילה עבור חברת Apple/Google אפליקציה של "שליש גן עדן" (להלן:"
							האפליקציה"). מפעילת האתר תבהיר כי כל פעולה באפליקציה, לרבות רכישת מנוי, מבוצעת בין
							המנוי אל מול חברת Apple/Google בלבד, בהתאם לתנאי ההתקשרות אשר ייקבעו על ידי
							Apple/Google מעת לעת ובהתאם ל – App Store Terms of Service, או Google Play Terms,
							לרבות בכל הנוגע לדין החל על ההתקשרות והרכישה וכן בכל הנוגע למדיניות החזרות וזיכויים
							ומפעילת האתר כלל אינה צד להתקשרות הלקוח עם חברת Apple/Google, למעט בקשר עם אחריותה
							לתקינות פעילות האפליקציה, כספק של חברת Apple/Google.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							בהתאם, מפעילת האתר אינה אחראית לכל בקשה ו/או טענה ו/או תביעה לביטול מנוי ו/או החזר
							כספי מכל סיבה שהיא וכל טענה ו/או דרישה בקשר עם האפליקציה יש להפנות ישירות לחברת
							Apple/Google.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							במידת הרלוונטיות, הוראות תקנון זה תחשבנה כהסכם לטובת צד שלישי בכל הנוגע לחברת
							Apple/Google, כמוטב ובאישור תקנון זה הנך מעניק לאפל זכות לאכוף הסכם זה כנגדך ו/או כנגד
							צדדים שלישיים.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							משתמש הנרשם לאתר מאשר את קבלת Push Notifications ממפעילת האתר על מספר המכשיר הסלולרי
							שהוזן על ידו. משתמש יוכל לבטל את ההתראות אלו רק באמצעות הגדרת התראות אפליקציה שבמכשיר
							הסלולרי.
						</span>
					</p>
				</li>
			</ol>
			<h1 dir="rtl" style={{ textAlign: "justify" }}>
				<strong>
					<span style={{ fontSize: "11pt" }}>מדיניות פרטיות, אבטחת מידע, עוגיות (cookies)</span>
				</strong>
			</h1>
			<ol start={86}>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							השימוש ביישומי מפעילת האתר כפוף למדיניות הפרטיות בה. בעצם השימוש ביישומי האתר אתה נחשב
							כמי שקרא והסכים למדיניות הפרטיות של האתר.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							ניתן לעיין במדיניות הפרטיות של מפעילת האתר להלן.
						</span>
					</p>
				</li>
			</ol>
			<h1 dir="rtl" style={{ textAlign: "justify" }}>
				<strong>
					<span style={{ fontSize: "11pt" }}>הצהרת נגישות</span>
				</strong>
			</h1>
			<ol start={88}>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							בהתאם להוראות חוק שוויון זכויות לאנשים עם מוגבלות, החל ממועד העלאת האתר לאוויר בגרסתו
							הנוכחית, בוצעו וממשיכות להתבצע באתר התאמות נגישות עבור אנשים עם מוגבלות, ההתאמות בוצעו
							בגלישה באמצעות המחשב או בגלישה באמצעות האתר המותאם בסלולר, הפעלת/הסתרת תפריט הנגישות
							מתבצע בלחיצה על כפתור הנגישות המופיע באתר. האפליקציה אינה מונגשת.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							הנגשת האתר נועדה להתאימו לשימוש, כך שיהיה זמין ונוח עבור אוכלוסיות בעלות צרכים
							מיוחדים. התאמות אלו נעשות בהתאם לתקנות החוק, כמו כן, בדיקת נגישות בוצעה בדפדפני
							מייקרוסופט וגוגל כרום בגרסאות עדכניות.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							על כל פנים, כל שירות שאינו מתאפשר ויכול להתבצע באמצעות מוקד שירות הלקוחות, אנחנו כאן
							בשבילכם ונשמח לעזור בשיחה למוקד בטלפון או בוואטסאפ שמספרו 086519660, במידה ומוקד שירות
							הלקוחות של האתר אינו זמין, ישנה אפשרות להשארת מספר טלפון לחזרה אליכם במועד מאוחר יותר,
							ואנו נחזור אליכם בהקדם האפשרי. עם כל תלונה על העדר התאמות נגישות או בקשה אחרת יש לפנות
							למוקדי השירות שלנו בטלפון 086519660 או בכתובת הדואר האלקטרוני info@shlish.co.il, או
							בפקס שמספרו 086217553.
						</span>
					</p>
				</li>
			</ol>
			<h1 dir="rtl" style={{ textAlign: "justify" }}>
				<strong>
					<span style={{ fontSize: "11pt" }}>שיפוי</span>
				</strong>
			</h1>
			<ol start={91}>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							אתה מסכים לשפות ולפצות את ההנהלה, עובדיה, מנהליה, בעלי מניותיה או מי מטעמם בגין כל
							נזק, הפסד, אבדן רווח, תשלום או הוצאה שייגרמו להם, ובכלל זה שכר טרחת עו"ד והוצאות משפט,
							עקב הפרת תנאי שימוש אלה.
						</span>
					</p>
				</li>
			</ol>
			<h1 dir="rtl" style={{ textAlign: "justify" }}>
				<strong>
					<span style={{ fontSize: "11pt" }}>סמכות שיפוט והדין</span>
				</strong>
			</h1>
			<ol start={92}>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							תנאי שימוש אלו וכל הסכם אחר שנוצר על ידי ההנהלה הקשור בשימוש באתר, יהיו כפופים לדיני
							מדינת ישראל. סמכות השיפוט הבלעדית לדון בכל סכסוך העולה מהסכם זה תהיה נתונה לבתי המשפט
							בתל אביב.&nbsp;
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							תניית שיפוט זו גוברת על כל הסכמה או חוזה, ככל שהייתה, אם בכתב, בעל פה או בכל דרך אחרת,
							בין הצדדים.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							חוזה זה כפוף אך ורק לדין הישראלי, מבלי להשפיע על סוגיות במשפט בינלאומי פרטי.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							על חוזה זה יחולו הוראות חוק החוזים (תרופות בשל הפרת חוזה), התשל"א – 1970. בלי לגרוע מן
							הכלליות או לגרוע מן הסעדים הניתנים על פי הוראות כל דין.
						</span>
					</p>
				</li>
			</ol>
			<p>
				<br />
			</p>
			<p dir="rtl" style={{ textAlign: "justify" }}>
				<strong>
					<u>
						<span style={{ fontSize: "11pt" }}>מדיניות פרטיות</span>
					</u>
				</strong>
			</p>
			<p dir="rtl" style={{ textAlign: "justify" }}>
				<span style={{ fontSize: "11pt" }}>
					מדיניות פרטיות זו מהווה חלק בלתי נפרד מתקנון האתר ותנאי השימוש בו. השימוש בשירותי האתר
					ו/או היישומים הנלווים לו, כפוף לתנאי השימוש ומדיניות פרטיות זו ומעיד על הסכמה לתנאים אלה.
				</span>
			</p>
			<p dir="rtl" style={{ textAlign: "justify" }}>
				<span style={{ fontSize: "11pt" }}>
					אם אינך מסכים למדיניות השמירה על הפרטיות, הינך נדרש שלא להירשם לשירותי האתר.
				</span>
			</p>
			<p dir="rtl" style={{ textAlign: "justify" }}>
				<strong>
					<span style={{ fontSize: "11pt" }}>כללי</span>
				</strong>
			</p>
			<ol>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							הפרטיות שלך חשובה לנו מאוד. על מנת להבין טוב יותר איזה מידע אנו אוספים ומהו השימוש
							שאנו עושים במידע זה, אנא קרא את מדיניות הפרטיות המפורטת להלן.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מדיניות פרטיות זו חלה על מידע הנאסף במסגרת השימוש שלך ביישומי החברה בלבד ובהתאם להסכמת
							המשתמש בתנאים שנזכרים להלן.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							בעצם השימוש באתר אתה מסכים ומאשר את מדיניות הפרטיות שלהלן.
						</span>
					</p>
				</li>
			</ol>
			<p dir="rtl" style={{ textAlign: "justify" }}>
				<span style={{ fontSize: "11pt" }}>
					אנו עשויים לשנות ו/או לערוך ו/או לעדכן את מדיניות הפרטיות מעת לעת. אתה מוזמן לבדוק את
					העמוד מדי פעם כדי לוודא שאתה מכיר את כל השינויים.
				</span>
			</p>
			<p dir="rtl" style={{ textAlign: "justify" }}>
				<strong>
					<span style={{ fontSize: "11pt" }}>מידע אודות המשתמש</span>
				</strong>
			</p>
			<ol start={4}>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מדיניות פרטיות זו מסבירה כיצד שליש גן עדן בע"מ (להלן: "
						</span>
						<strong>
							<span style={{ fontSize: "11pt" }}>החברה</span>
						</strong>
						<span style={{ fontSize: "11pt" }}>
							") משתמשת, אוספת ושומרת את המידע שאתה מספק ומוסר באתרי וביישומי החברה (להלן: "
						</span>
						<strong>
							<span style={{ fontSize: "11pt" }}>האתר</span>
						</strong>
						<span style={{ fontSize: "11pt" }}>") והכל בכפוף לדיני מדינת ישראל.</span>
					</p>
				</li>
			</ol>
			<p dir="rtl" style={{ textAlign: "justify" }}>
				<strong>
					<span style={{ fontSize: "11pt" }}>סוג הפרטים והמידע שנאספים</span>
				</strong>
			</p>
			<ol start={5}>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							החברה רשאית לאסוף, לעבד ולשמור (לרבות בשרתים מחוץ לישראל) את המידע המפורט להלן ולעשות
							בו שימוש על פי מדיניות פרטיות זו:
						</span>
					</p>
				</li>
			</ol>
			<ol>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							כל מידע אשר נמסר לחברה בעת ההרשמה והשימוש באתר. המתבצעת באמצעות מילוי פרטים בטופס
							הרשמה, ובכלל זה עשויים לכלול פרטים אישיים, המוגדרים כ"מידע רגיש", על פי דין, כגון: שם,
							תצלומים, מקום מגורים, עיסוק, תחביבים, תחומי עניין, נתונים פיזיים ותכונות ומאפיינים
							אישיים שונים, תאריך לידה, מצב אישי, השכלה, תכתובות בינך לבין משתמשים, מידע פיננסי
							(כגון פרטי כרטיס אשראי) וכיו"ב (להלן: "
						</span>
						<strong>
							<span style={{ fontSize: "11pt" }}>הפרטים האישיים</span>
						</strong>
						<span style={{ fontSize: "11pt" }}>
							"), אשר ייאספו, יעובדו וישמרו ויעשה בהם שימוש על ידי החברה.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							פעולות שונות באתר עשויות לדרוש ממך לספק פרטים שונים, כגון: שם מלא, מספרי טלפון, כתובות
							דואר ודואר אלקטרוני, מספר תעודת זהות ומספר כרטיס אשראי, אשר ייתכן שחלקם יאספו ויאוחסנו
							על ידי החברה.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מידע אודות השימוש שלך באתר, מידע אודות פעולות ו/או עסקאות הנעשות באמצעות האתר, מקור
							ההפניה לאתר, כל מידע בנוגע לשימוש ולצפיה באתר ו/או כל מידע אחר שאתה מוסר ו/או מעלה
							ו/או מפרסם על גבי האתר.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מידע אישי בדבר מיקום המשתמש, לרבות שמירה של היסטוריית המיקומים של המשתמש, כתובת IP,
							מידע בדבר איכון המיקום, מידע על מסלול המשתמש (לדוגמה בצורה של אותות GPS) ומידע אחר אשר
							נשלח על-ידי המכשיר הסלולארי שבו מותקן ומופעל יישום שליש גן עדן ו/או יישומים ו/או
							שירותים אחרים של החברה ו/או מי מטעמה.
						</span>
					</p>
				</li>
			</ol>
			<p dir="rtl" style={{ textAlign: "justify" }}>
				<strong>
					<span style={{ fontSize: "11pt" }}>הצגת מיקום ושימוש במיקום</span>
				</strong>
			</p>
			<ol start={6}>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							ככל והמשתמש אישר את הצגת מיקומו, החברה רשאית להציג את המיקום האחרון של המשתמש, כמיקום
							היסטורי - המקום בו נצפה המשתמש לאחרונה. לרבות על ידי הצגת מיקום המכשיר הסלולארי שבו
							מותקן ומופעל יישום שליש גן עדן ו/או יישומים ו/או שירותים אחרים של החברה ו/או מי מטעמה.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							בנוסף, מפעילת האתר רשאית לעשות שימוש במידע איכון המיקום, על מנת לנהל מסעות פרסום, בין
							היתר, על בסיס מיקום גיאוגרפי.
						</span>
					</p>
				</li>
			</ol>
			<p dir="rtl" style={{ textAlign: "justify" }}>
				<strong>
					<span style={{ fontSize: "11pt" }}>הצגת ושימוש במידע פרטי באתר&nbsp;</span>
				</strong>
			</p>
			<ol start={8}>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מטבע הדברים, על מנת למצוא שידוך, מפעילת האתר תציג את פרטי כרטיס המשתמש והפרטים האישיים
							בפני משתמשי האתר (לרבות גולשים שאינם רשומים לאתר) בכלל הפלטפורמות השונות של החברה,
							העוסקות בכך. הן בפלטפורמות להצגת משתמשים לצורכי היכרויות שהחברה משווקת מתוצרתה, והן
							בפלטפורמות שיווק חיצוניות, של צדדים שלישיים, שהחברה עושה בהם שימוש.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							בנוסף, המידע הנזכר לעיל עשוי בין השאר לשמש לצורך:
						</span>
					</p>
				</li>
			</ol>
			<ol>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>שיפור הגישה שלך לאתר ולשימוש בשירותי החברה;</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							התאמה אישית של האתר ושירותיו וכן שירותים נוספים של החברה ו/או מי מטעמה;
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>שיפור שירות הלקוחות;</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>ניהול טוב יותר את החברה ויישומיה;</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							הספקת השירותים ו / או המוצרים שהחברה ו/או מי מטעמה מספקים;
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							משלוח הדו"חות, תקשורת שיווקית, מיילים בדבר שירותים, פרסומות וכיו"ב;
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>עיבוד העסקאות שנעשו באמצעות יישומי החברה;</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>פרסום מידע ביישומי החברה אודותיך;</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							הצגה או משלוח של חומרי שיווק ופרסום, לרבות שימוש במידע איכון על מנת להציג פרסומות
							מותאמות מבוססות נתונים ו/או מיקום גיאוגרפי.
						</span>
					</p>
				</li>
			</ol>
			<ol start={10}>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							אנו עשויים לעשות שימוש במידע פיננסי שלך באתר על מנת לבצע התאמה לשימוש באתר, לבצע
							חיובים עתידים באתר.
						</span>
					</p>
				</li>
			</ol>
			<p dir="rtl" style={{ textAlign: "justify" }}>
				<strong>
					<span style={{ fontSize: "11pt" }}>שימוש במידע על ידי צדדים שלישיים</span>
				</strong>
			</p>
			<ol start={11}>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							אנו עשויים לחשוף את המידע האישי שלך לסוכנים שלנו או לקבלני המשנה שלנו או לכל צד שלישי
							אחר למטרות אלה.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							בנוסף, אנו עשויים לחשוף מידע אישי ופרטי אם וכאשר אנו סבורים כי שחרור כזה הוא ראוי ו /
							או נחוץ בכדי לציית לחוק, כדי לקיים את פסיקת בית משפט ו / או צו בית משפט, כדי להגן על
							זכויותינו ועל זכויותיהם של צדדים אחרים ו / או על רכושם ועל מנת לאכוף את המדיניות באתר
							וביישומיו.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							בנוסף, אנו עשויים לעשות שימוש במידע אודותיך לצורך הצגה מותאמת של תכנים במערכות
							,Google, Facebook, Apple, Outbrain, Taboola, Tiktok וכיו"ב.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							אנו עשויים לעשות שימוש בשירותי חברת פרסום כדי להציג מודעות מטעמנו ברשת. חברה זו עשויה
							לאסוף מידע פרטי על הגלישה שלך באתר זה. צד שלישי זה עשוי להשתמש במידע על ביקוריך באתרי
							האינטרנט שלנו לצורך פרסום על מוצרים ושירותים העשויים לעניין אותך.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							אנו עשויים לספק מידע פיננסי אודותיך לספקי השירותים שלנו ולכל צד שלישי וזאת לצורך תשלום
							על מנוי, תמיכה וייעול הפעילויות שלך באתר. צד שלישי זה עשוי להיות, חברות כרטיסי האשראי
							ומוסדות בנקאיים המטפלים ותומכים בעסקה או בפעילות האתר. אותם צדדים שלישיים יהיו רשאים
							לעשות שימוש במידע הפיננסי אודותיך, בכפוף להסדרים החוזיים שלנו עם צדדים שלישיים אלה,
							ובהתאם למדיניות לשמירה הפרטיות של אותם גופים.&nbsp;
						</span>
					</p>
				</li>
			</ol>
			<p dir="rtl" style={{ textAlign: "justify" }}>
				<span style={{ fontSize: "11pt" }}>
					ככל ויוצגו בפניך פרסומות באתר הן עשויות להיות מוצגות באמצעות מערכת פרסום פנימית ו/או
					חיצונית של צד שלישי &nbsp;(לדוגמא שירות של חברת גוגל בשם admob).
				</span>
			</p>
			<p dir="rtl" style={{ textAlign: "justify" }}>
				<strong>
					<span style={{ fontSize: "11pt" }}>תקשורת עמך</span>
				</strong>
			</p>
			<ol start={16}>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מספר הטלפון שלך, ו/או כתובת דואר אלקטרוני שסופקה על ידך לחברה לצורך רישום ו/או עיבוד
							תקשורת ו/או הזמנות ו/או יצירת קשר ו/או זיהוי ו/או עסקאות מכל סוג שהוא, תשמש למטרה של
							שליחת מידע, קבלות/חשבוניות, פרסומים, ועדכונים לשימוש שלך באתר האינטרנט, כמו גם לתקשורת
							עמך ו/או להזמנות שלך ולצרכים המפורטים במדיניות פרטיות זו, כפי שתתעדכן מעת לעת.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מפעילת האתר ו/או מי מטעמה, באמצעותה ובאמצעות צדדים נוספים מטעמה, רשאים לפנות למשתמשי
							האתר באמצעות הדואר האלקטרוני לצרכי: פרסום, תקשורת ומשלוח הודעות שונות.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							אם וכאשר אתה מעדיף להפסיק לקבל חומרי שיווק, פרסום ו/או הודעות דוא"ל עתידיות, אנא עקוב
							אחר ההוראות לביטול הרישום המפורטות בחלק התחתון של כל הודעת דוא"ל שאנו שולחים לך, או
							שלח לנו מייל לדואר info@shlish.co.il.
						</span>
					</p>
				</li>
			</ol>
			<p dir="rtl" style={{ textAlign: "justify" }}>
				<strong>
					<span style={{ fontSize: "11pt" }}>אבטחת מידע</span>
				</strong>
			</p>
			<ol start={19}>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							אנו ננקוט באמצעי זהירות טכניים וארגוניים סבירים כדי למנוע אובדן, שימוש לרעה או פגיעה
							במידע האישי שלך.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							אנו נוקטים באמצעים שונים כדי להבטיח את המידע האישי שהמשתמשים באתר נדרשים לספק לצורך
							גישה, כניסה, שליחה של מידע ותקשורת, או בכדי לשנות את המידע האישי שניתן על ידי המשתמש.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							בהתאם לאופי הפעילות באינטרנט, וכפי שקרה בעבר באתרים שונים בעולם. עלולים להיות מצבים
							שבו מידע רגיש מאתרי הכרויות דלף החוצה באמצעות שימוש לא חוקי, פריצה או מעשי זדון אחרים.
							עליך להימנע ממסירת מידע שאתה חושב שיש בו כדי לפגוע בפרטיותך.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							פעילות באתר דורשת חשיפת פרטים חריגה, מעבר למקובל בתחום הדייטינג וזאת בין היתר גם בשל
							אופי האתר והקהילה. החברה עושה מאמצים סבירים ומקובלים על מנת לאבטח את המידע היושב
							בשרתים וכן לעשות שימוש באחסון עיבוד ושליפת המידע באמצעות חברות צד ג' מוכרות וידועות,
							ובהתאם למיטב הבנתה ושיקול דעתה, אולם אם הנך רגיש לפרטיות שלך אל לך להירשם לאפליקציה,
							ובכל מקרה אל לך לרשום או למסור שום תוכן רגיש.
						</span>
					</p>
				</li>
			</ol>
			<p dir="rtl" style={{ textAlign: "justify" }}>
				<strong>
					<span style={{ fontSize: "11pt" }}>עוגיות (</span>
				</strong>
				<span style={{ fontSize: "11pt" }}>cookies</span>
				<strong>
					<span style={{ fontSize: "11pt" }}>)</span>
				</strong>
			</p>
			<ol start={23}>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							עוגייה היא מחרוזת אותיות ומספרים המשמשת לאימות, למעקב ולאגירת מידע על אודות גולש באתר
							אינטרנט, כגון שמירת העדפות המשתמש.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							ההסכמה לשימוש שלנו בעוגיות בהתאם לתנאים של מדיניות זו בזמן הביקור הראשון באתר שלנו,
							מאפשרת לנו להשתמש בעוגיות בכל פעם שאתם מבקרים באתר.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							אנו משתמשים בעוגיות למטרות שונות, לרבות, על מנת לפקח ולנתח נתונים על התנועה באתר,
							לזכור ולנתח פריטים המועברים על ידי המשתמש לאתר, כדי לשמור ולהתאים את העדפות המשתמש
							לשימוש עתידי באתר, חיסכון בצורך להזין מחדש את שם המשתמש והסיסמה בכל כניסה לשירות, על
							מנת להקל על השימוש באתר ו/או ביישום הסלולארי, על מנת לאסוף מידע סטטיסטי, לאמת את
							המידע, וכן לצורך אבטחת מידע וכל מטרה אחרת המפורטת במדיניות פרטיות זו.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							ניתן לחסום עוגיות דרך הגדרות הדפדפן. אפשרות זו עלולה לגרום לשירותים מסוימים שבאתר
							להציג תקלה ו/או שלא לפעול כראוי. במידת הצורך, המשתמש יכול עדיין לעשות שימוש באתר,
							במידת האפשר, על ידי פנייה ישירה לשירות הלקוחות בטלפון 086519660 או באמצעות פנייה
							לדוא"ל: info@shlish.co.il.
						</span>
					</p>
				</li>
			</ol>
			<p dir="rtl" style={{ textAlign: "justify" }}>
				<strong>
					<span style={{ fontSize: "11pt" }}>מחיקת מידע אישי</span>
				</strong>
			</p>
			<ol start={26}>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							עם קבלת בקשת משתמש למחיקת הכרטיס באתר והפסקת הפעילות של הלקוח באתר, אנו נפעל להפסקת
							הצגת המידע הפרטי שלך באתר ואולם, מפעילת האתר שומרת על זכותה להמשיך לדוור ולפרסם ללקוח
							ואף לשמור את המידע האישי במאגרי החברה.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							אם ברצונך כי פרטייך יימחקו כליל מן מהמאגר, או אם אין ברצונך להמשיך ולקבל מידע מסחרי
							כאמור, וברצונך לבטל את הסכמתך ולחדול מקבלת המידע המסחרי, תוכל לעשות זאת, בכל עת
							באמצעות פניה לכתובת דוא"ל &nbsp;info@shlish.co.il &nbsp;ואולם מחיקה מוחלטת מהמאגרים
							שלנו כפופה לסייגים הבאים:
						</span>
					</p>
				</li>
			</ol>
			<ol>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							מפעילת האתר תעשה כל מאמץ למחוק המידע האפשרי, אולם ייתכן שהדבר לא יוכל להתבצע באופן
							מיידי ממערכות הגיבוי.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							ייתכן ולא ניתן יהיה לבצע מחיקה של כל הנתונים ממערכות הגיבוי שלנו.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							כדי לאפשר לנו לבדוק, למנוע ולדווח לרשויות החוק על שימוש לרעה באתר אנו עשויים שלא לאפשר
							את המחיקה של הנתונים כגון: שם משתמש, מועד הרשמה, מועד ביצוע פעולות, כתובות IP. גם
							במקרה של חסימת משתמש על ידי האתר, הפרטים הרלוונטיים ישמרו בבסיס הנתונים וזאת בין היתר
							על מנת למנוע רישום מחודש לאתר.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							אנו עשויים לשמור במאגר מידע שנועד לצורך יישוב מחלוקות, לאיתור וטיפול בבעיות ולאכיפת
							הכללים ותנאים שלנו.
						</span>
					</p>
				</li>
			</ol>
			<p dir="rtl" style={{ textAlign: "justify" }}>
				<strong>
					<span style={{ fontSize: "11pt" }}>שירותים ומידע של צדדים שלישיים באתר</span>
				</strong>
			</p>
			<ol start={28}>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							תיתכן אפשרות לאפשר לאתר זה גישה לפרטים אישיים ומדיה (תמונות, סרטים וכד') הנמצא באתרים
							חיצוניים (אתרי מדיה חברתית, וכד'). בנוסף, יתכנו דרכים שונות להתחבר וליצור משתמש (USER)
							ו/או להעלות ו/או ליצור קישור לפרטים אישיים באתר. במקרים מסוימים ניתן לבצע חיבור לאתר
							וזיהוי פרטי המשתמש דרך אתרים חיצוניים, כגון חיבור לחשבון Facebook או חשבון
							Google/Apple ואף יתכן שחלק מהמידע יאוחסן בשרתים חיצוניים, כגון &nbsp;שימוש ב Google
							cloud, Amazon, &nbsp;Cloudinary, וכדומה.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							יובהר, כי אין באפשרות האתר לפקח או לנטר על מידע שמועבר מאתרים חיצוניים ועל כן לא נהיה
							אחראים להפרות פרטיות הנגרמות עקב הזדהות או העברת נתונים מאתרים חיצוניים אלו.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							יובהר, כי במתן ההרשאה לחיבור האתר עם האתרים החיצוניים הנך מאפשר לאתר גישה ושימוש מלא
							לפרטים ולמדיה אשר העלית לאותם אתרים.
						</span>
					</p>
				</li>
			</ol>
			<p dir="rtl" style={{ textAlign: "justify" }}>
				<strong>
					<span style={{ fontSize: "11pt" }}>תנאי שימוש</span>
				</strong>
			</p>
			<ol start={31}>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							השימוש ביישומי החברה כפוף לתנאי השימוש בה, המפרטים ומרחיבים את תנאי השימוש, הויתורים
							וההגבלות השונות. בעצם השימוש ביישומי החברה אתה נחשב כמי שקרא והסכים לתנאי השימוש בה.
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							ניתן לעיין בתנאי השימוש של החברה בכתובת: https://shlish.co.il/terms
						</span>
					</p>
				</li>
				<li dir="rtl" style={{ listStyleType: "decimal", fontSize: "11pt" }}>
					<p dir="rtl" style={{ textAlign: "justify" }}>
						<span style={{ fontSize: "11pt" }}>
							שים לב כי נציגי האתר ועובדיו בודקים דיווחים שונים של משתמשים שונים בקשר לתוכן ההודעות
							של משתמשי האתר. לרבות ביחס לאופיו של אתר, המתמחה בקהל מסורתי. התנהלות זו הכוללת שמירה
							על אופי האתר ועל תכניו הנם חלק מאבני היסוד של האתר. והינך מנוע מלטעון כל טענה בקשר
							לכך.
						</span>
					</p>
				</li>
			</ol>
			<p dir="rtl" style={{ textAlign: "justify" }}>
				<strong>
					<span style={{ fontSize: "11pt" }}>יצירת קשר</span>
				</strong>
			</p>
			<p dir="rtl" style={{ textAlign: "justify" }}>
				<span style={{ fontSize: "11pt" }}>
					בכל שאלה ו/או בקשה הקשורה לסוגיות פרטיות ומדיניות פרטיות זו, אנא אל תהססו ליצור עמנו קשר
					ב: חברת שליש גן עדן בע"מ (ח.פ 514623446), מען למכתבים: גולדה מאיר 3 נס ציונה, מספר טלפון:
					08-6519660, כתובת דואר אלקטרוני: info@shlish.co.il
				</span>
			</p>
		</>
	);
};
