import firebase from "firebase/app";
import "firebase/database";

class SystemService {
	BASE_REF = "system/appSettings";
	PUBLIC_REF = "system/public";

	async fetchAppSettings() {
		return (await firebase.database().ref(this.BASE_REF).once("value")).val();
	}
	async fetchSiteEntrancePopupData() {
		return (
			await firebase.database().ref(this.PUBLIC_REF).child("siteEntrancePopup").once("value")
		).val();
	}
}

export default new SystemService();
